import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TokenStorageService } from './token-storage.service';
import { environment } from './../../../environments/environment';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient,private tokenStorageService:TokenStorageService) { }
  
  login(identifier: string, password: string): Observable<any> {
    return this.http.post(environment.api + "auth/local/", {
      identifier,
      password
    }, httpOptions);
  }

  register(username: string, email: string, password: string,type: string): Observable<any> {
    return this.http.post(environment.api + 'auth/local/register', {
      username,
      email,
      password,
      type
    }, httpOptions);
  }

  register_with_phone(username, email: string, password: string, provider: string): Observable<any> {
    username = "0" + String(username * 1);
    return this.http.post(environment.api + 'auth/local/register', {
      username,
      email,
      password,
      type: 'artiste',
      provider
    }, httpOptions);
  }

  resetPassword(code:string,password: string,passwordConfirmation: string): Observable<any> {
    return this.http.post(environment.api + 'auth/reset-password', {
      code,
      password,
      passwordConfirmation
    }, httpOptions);
  }
  
  forgotPassword(email: string): Observable<any> {
    return this.http.post(environment.api + 'auth/forgot-password', {
      email
    }, httpOptions);
  }

  accountCreatedEmail(data:any) {

    return this.http.post(environment.api + "emails/account", data, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }
}
