import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  endPoint = 'products/';
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create(token_price:string,token_product:string,form: any,features:any): Observable<any> {
    const datas = {
      "name": form.name,
      "description": form.description,
      "features": features,
      "price":  Math.round( form.price * 100 ),
      "token_price": token_price,
      "token_product": token_product,
    }
    return this.http.post(environment.api + this.endPoint,  JSON.stringify(datas), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }
  get(): Observable<any> {
    return this.http.get(environment.api + this.endPoint, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }
}
