import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MediasService {

  constructor(private httpClient: HttpClient,private tokenStorageService:TokenStorageService) { }

  delete_pictures(pictureId:string){
    return this.httpClient.delete(environment.api + "upload/files/" + pictureId,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }
}
