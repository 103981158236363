import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReservationService } from '../../../../app/services/reservation/reservation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { StudioService } from '../../../../app/services/studio/studio.service';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { PrestationsService } from '../../../../app/services/prestations/prestations.service';
import { PaymentService } from '../../../../app/services/payment/payment.service';
import { TokenStorageService } from '../../../../app/services/auth/token-storage.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class DetailsComponent implements OnInit {


  paymentType = ['Pré-Réservation', 'pass Culture', 'stripe', 'paypal', 'espèces']
  Form: FormGroup;
  reservation: any;
  loading = true;
  avatar = environment.defaultAvatar;
  urlOffre: string = "https://passculture.app/offre";
  //urlOffre : string = "https://integration.passculture.app/offre";

  base_api: string = environment.base_api;
  //autocomplete
  studios: any;
  selectedStudio: any;
  selectedStudioId: any;
  @ViewChild("itemTemplate") itemTemplate: any;
  keyword = 'name';

  // date
  model: NgbDateStruct;
  toDay: NgbDate | null;
  startDate: any;
  endDate: any;
  etats = ['Annulée', 'Réservation en attente', 'Réservation confirmée, En attente de paiement', 'Réservation validée', 'Réservation acceptée'];
  prestationTypes = [];
  prestationsByType = [];
  tarif_prestation = 0;
  total_prestation = 0;
  currentTarif = 0;
  currentTotal = 0;
  currentStudio: any;
  studioList = [];
  contactStudioList = [];
  loadingStudio = true;
  loadingContactStudio = true;

  changingStartDate = false;
  changingEndDate = false;



  constructor(private ngZone: NgZone, private token: TokenStorageService, private router: Router, private paymentService: PaymentService, private prestationsService: PrestationsService, private location: Location, private reservationService: ReservationService, private spinner: NgxSpinnerService, private fb: FormBuilder, private route: ActivatedRoute, private studioService: StudioService, private calendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) {
    this.Form = this.fb.group({
      payment_type: ['', Validators.required],
      selectedStudio: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startHour: ['', Validators.required],
      endHour: ['', Validators.required],
      nbr_hours: [],
      pay: [],
      etat: ['Réservation en attente'],
      prestation_type: [],
    })
    this.toDay = calendar.getToday();
    this.endDate = this.dateAdapter.toModel(this.calendar.getToday());
    this.startDate = this.dateAdapter.toModel(this.calendar.getPrev(calendar.getToday(), 'm', 1));
  }

  ngOnInit(): void {
    this.loadStudios();
    this.route.params.subscribe(params => {
      this.spinner.show();
      this.reservationService.get_by_id(params.id).subscribe(res => {
        this.reservation = res;
        // convert reservation prestation to array if it's null to avoid error
        this.reservation.prestations = (this.reservation.prestations) ? this.reservation.prestations : [];
        this.Form.controls.nbr_hours.setValue(this.reservation.horaires.length);
        this.Form.controls.pay.setValue(this.reservation.pay);
        if (this.reservation.studio) {
          this.selectedStudioId = this.reservation.studio.id;
          this.initialyzeStudioData(res.studio);
          this.Form.controls.selectedStudio.setValue(this.reservation.studio.name ? this.reservation.studio.name : '');
        }
        this.Form.controls.payment_type.setValue((this.reservation.payment) ? this.reservation.payment.type : null);
        if (this.reservation.payment) {
          this.initialyzePaymentType(this.reservation);
        }
        if (this.currentStudio && !this.currentStudio.id_deleted && !this.currentStudio.is_draft) {
          this.suggestedStudios(res.studio);
        } else {
          this.loadingStudio = false;
        }
        // get full date
        let start_full_date = this.showDateHour(this.reservation.start_date);
        let end_full_date = this.showDateHour(this.reservation.end_date, true);
        //  get date
        let start_date = start_full_date.split(" ")[0];
        let end_date = end_full_date.split(" ")[0];
        // get hour
        let start_hour = start_full_date.split(" ")[1];
        let end_hour = end_full_date.split(" ")[1];
        // set date
        let startDate = new NgbDate(Number(start_date.split('-')[2]), Number(start_date.split('-')[1]), Number(start_date.split('-')[0]));
        let endDate = new NgbDate(Number(end_date.split('-')[2]), Number(end_date.split('-')[1]), Number(end_date.split('-')[0]));

        this.Form.controls.endDate.setValue(this.dateAdapter.toModel(endDate));
        this.Form.controls.startDate.setValue(this.dateAdapter.toModel(startDate));
        // set time
        this.Form.controls.startHour.setValue({ hour: (Number(start_hour.split(':')[0])), minute: (Number(start_hour.split(':')[1])) });
        this.Form.controls.endHour.setValue({ hour: (Number(end_hour.split(':')[0])), minute: (Number(end_hour.split(':')[1])) });
        this.Form.get('startHour').valueChanges.subscribe(x => {
          this.onDateSelect();
        });
        this.Form.get('endHour').valueChanges.subscribe(x => {
          this.onDateSelect();
        });
        this.contact_studio(this.reservation.id);
        //this.spinner.hide();
      }).add(() => {
        this.loading = false;
      });
    });

  }

  onSubmit() {

    let data: any;

    if (this.Form.invalid) {
      Swal.fire('Erreur', "Veuillez remplir tous les champs", "error");
      return;
    }
    if (this.Form.value.nbr_hours <= 0) {
      Swal.fire('Erreur', "le nombre ne peut pas être inférieur ou égale à 0", "error");
      return;
    }
    if (this.reservation.prestations.length == 0) {
      Swal.fire('Erreur', "il est obligé de choisir une préstation au moins", "error");
      return;
    }
    data = {
      ... {
        "studio": this.selectedStudioId,
        "total": this.total_prestation,
        "pay": this.tarif_prestation,
        "start_date": this.objectToMoment('startDate', 'startHour'),
        "end_date": this.objectToMoment('endDate', 'endHour', true),
        "prestations": this.reservation.prestations
      }, ...{ etat: false, id_deleted: false }
    };


    Swal.fire({
      title: "Étes-vous sûre de vouloir enregistrer cette réservation ?",
      cancelButtonText: 'NON',
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: `OUI`,

    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.reservationService.update_reservation(this.reservation.id, data).subscribe(res => {
          this.paymentService.update(this.reservation.payment.id, { type: "waiting" }).subscribe(result => {
            Swal.fire('Réservation', "Modifée avec succès!", "success");
            this.router.navigate(['/reservations']);
          }).add(() => {
            this.spinner.hide();
          });
        }, err => {
          Swal.fire('Erreur', "Une érreur est survenu", "error");
          this.spinner.hide();
        }).add(() => {

        });
      }
    });



  }

  selectEvent(item) {
    // do something with selected item
    this.selectedStudioId = item.id;
    this.studioService.get_by_id(item.id).subscribe(studio => {
      this.initialyzeStudioData(studio);
      this.reservation.prestations = [];
    })

  }
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  onSelectStartDate() {
    if (!this.changingStartDate) {
      this.changingStartDate = true;

      const startDate = this.Form.value.startDate;
      const startHour = this.Form.value.startHour;

      const startDateTime = moment.utc({
        year: startDate.year,
        month: startDate.month - 1,
        date: startDate.day,
        hour: startHour.hour,
        minute: startHour.minute
      });

      const endDateTime = moment.utc(startDateTime).add(this.reservation.horaires.length, 'hours');

      this.ngZone.run(() => {
        this.Form.patchValue({
          endDate: {
            year: endDateTime.year(),
            month: endDateTime.month() + 1,
            day: endDateTime.date(),
          },
          endHour: {
            hour: endDateTime.hours(),
            minute: endDateTime.minutes(),
            second: 0,
          }
        });
      });

      this.onDateSelect();
      this.changingStartDate = false;
    }
  }

  onSelectEndDate() {
    if (!this.changingEndDate) {
      this.changingEndDate = true;

      const endDate = this.Form.value.endDate;
      const endHour = this.Form.value.endHour;

      const endDateTime = moment.utc({
        year: endDate.year,
        month: endDate.month - 1,
        date: endDate.day,
        hour: endHour.hour,
        minute: endHour.minute
      });

      const startDateTime = moment.utc(endDateTime).subtract(this.reservation.horaires.length, 'hours');

      this.ngZone.run(() => {
        this.Form.patchValue({
          startDate: {
            year: startDateTime.year(),
            month: startDateTime.month() + 1,
            day: startDateTime.date(),
          },
          startHour: {
            hour: startDateTime.hours(),
            minute: startDateTime.minutes(),
            second: 0,
          }
        });
      });

      this.onDateSelect();
      this.changingEndDate = false;
    }
  }

  onDateSelect() {
    let date1 = this.objectToMoment('startDate', 'startHour');
    let date2 = this.objectToMoment('endDate', 'endHour');
    this.Form.controls.nbr_hours.setValue(moment(date2).diff(moment(date1), 'hours'));
    this.tarif_prestation = this.Form.value.nbr_hours * this.currentTarif;
    this.total_prestation = this.Form.value.nbr_hours * this.currentTotal;

  }

  loadStudios() {
    this.spinner.show();
    this.studioService.get().subscribe(studio => {
      this.studios = studio.map(items => {
        return { id: items.id, name: items.name }
      })
    }).add(() => {
      this.spinner.hide();
    })
  }

  back() {
    this.location.back();
  }

  objectToMoment(date: any, hour: any, is_endDate?: boolean) {
    if (is_endDate) {
      return moment(this.Form.controls[date].value.year + "-" + this.Form.controls[date].value.month + "-" + this.Form.controls[date].value.day + " " + this.Form.controls[hour].value.hour + ":" + this.Form.controls[hour].value.minute).add("hour", -1).format("YYYY-MM-DD HH:mm");
    } else {
      return moment(this.Form.controls[date].value.year + "-" + this.Form.controls[date].value.month + "-" + this.Form.controls[date].value.day + " " + this.Form.controls[hour].value.hour + ":" + this.Form.controls[hour].value.minute).format("YYYY-MM-DD HH:mm");
    }
  }


  showDateHour(date: any, is_endDate?: boolean) {
    if (is_endDate) {
      return moment(date).utcOffset(0).add("hour", 1).format('DD-MM-YYYY HH:mm');
    } else {
      return moment(date).utcOffset(0).format('DD-MM-YYYY HH:mm');
    }
  }

  GoToUrlOffre(id: any) {
    window.open(this.urlOffre + "/" + id, '_blank');
  }

  checked(item) {
    if (this.reservation.prestations.indexOf(item) != -1) {
      return true;
    }
  }

  // when checkbox change, add/remove the item from the array
  onChange(checked, item) {
    if (checked) {
      this.reservation.prestations.push(item);
    } else {
      this.reservation.prestations.splice(this.reservation.prestations.indexOf(item), 1)
    }
  }

  initialyzePaymentType(reservation: any) {
    let etat = "";
    switch (reservation.payment.type) {
      case "cancel":
        etat = "Annulée";
        // set type to Pré-Réservation show it on select
        this.Form.controls.payment_type.setValue("Pré-Réservation");
        // set payment type to Pré-Réservation to unlock inputs for edition
        reservation.payment.type = "Annulée";
        break;
      case "Pré-Réservation":
        etat = (reservation.etat && !reservation.is_deleted) ? "Réservation validée" : "Réservation en attente";
        // remove 'Réservation validée' & 'Réservation acceptée' from etats array
        this.etats.splice(4, 1);
        this.etats.splice(3, 1);

        // can only cancel if studio is draft or deleted
        if (reservation.studio.is_deleted || reservation.studio.is_draft) {
          this.etats.splice(2, 1);
        }
        break;
      case "waiting":
        if (reservation.name != 'hotline') {
          // set type to Pré-Réservation show it on select
          this.Form.controls.payment_type.setValue("Pré-Réservation");
          // set payment type to Pré-Réservation to unlock inputs for edition
        }
        etat = "Réservation confirmée, En attente de paiement";
        reservation.payment.type = "Réservation confirmée, En attente de paiement";
        break;
      default:
        etat = reservation.etat;
        break;
    }
    if (reservation.payment.type == "stripe" || reservation.payment.type == "paypal" || reservation.payment.type == "espèces" || reservation.payment.type == "pass Culture") {

      etat = (reservation.membre) ? "Le gérant du studio a accepté la réservation." : "La réservation a été payée. Le client attend l'acceptation du gérant";
    }
    // set value shown on select etat
    this.Form.controls.etat.setValue(etat);
  }

  selectType() {
    this.suggestedStudios(this.currentStudio);
    this.prestationTypes.map(x => {
      if (x.name.includes(this.Form.value.prestation_type)) {
        this.prestationsByType = x.prestations;
        this.currentTarif = (x.tarif_promo) ? x.tarif_promo : x.tarif;
        this.tarif_prestation = this.Form.value.nbr_hours * this.currentTarif;
        this.currentTotal = x.tarif;
        this.total_prestation = this.Form.value.nbr_hours * this.currentTotal;
      };
    })
  }

  initialyzeStudioData(studio: any) {
    this.currentStudio = studio;
    if (this.reservation.type_selected) {
      this.Form.controls.prestation_type.setValue(this.reservation.type_selected);
    } else {
      this.Form.controls.prestation_type.setValue(studio.types[0].name);
    }

    //get only "PHOTOGRAPHIE" || "ENREGISTREMENT" types
    this.prestationTypes = studio.types.filter(i => i.name == "PHOTOGRAPHIE" || i.name == "ENREGISTREMENT");
    this.prestationsByType = this.prestationTypes[0].prestations;
    this.currentTarif = (this.prestationTypes[0].tarif_promo) ? this.prestationTypes[0].tarif_promo : this.prestationTypes[0].tarif;
    this.tarif_prestation = this.Form.value.nbr_hours * this.currentTarif;
    this.currentTotal = this.prestationTypes[0].tarif;
    this.total_prestation = this.Form.value.nbr_hours * this.currentTotal;
  }

  suggestedStudios(studio: any) {
    this.studioList = [];
    if (!this.studioList.some(x => x.id == studio.id)) {
      this.studioList.push(studio);
    }
    this.spinner.show();
    this.studioService.get_studios_arround(this.Form.value.prestation_type, '', '', studio.lat, studio.lng, this.currentTarif - 5, this.currentTarif + 20, 0, 50).then((studios: any) => {
      studios.map(x => {
        if (this.studioList.length < 10 && x.id != studio.id) {
          this.studioList.push(x);
        }
      }
      )
      this.loadingStudio = false;
    }, err => {
      this.loadingStudio = false;
    }).finally(() => {
      this.spinner.hide();
    });
  }

  cancelReservation(reservation: any) {
    Swal.fire({
      title: "Étes-vous sûre de vouloir annuler cette réservation ?",
      cancelButtonText: 'NON',
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: `OUI`,

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.spinner.show();
        this.reservationService.update_reservation(reservation.id, { "is_deleted": true, "close_by": this.token.getUser().id }).subscribe(reservation => {
          this.paymentService.update(reservation.payment.id, { type: ((this.reservation.payment.type == 'stripe' || this.reservation.payment.type == 'paypal' || this.reservation.payment.type == 'espèces')) ? this.reservation.payment.type : 'cancel' }).subscribe(
            res => {
              Swal.fire('Réservation', 'Annulée avec succès!', 'success');
              this.router.navigate(['reservations']);
            }, err => {
              Swal.fire('Érreur!', 'une érreur est survenue', 'error');
            }).add(() => {
              this.spinner.hide();
            }
            );
        }, err => {
          Swal.fire('Érreur!', 'une érreur est survenue', 'error');
          this.spinner.hide();
        }).add(() => {

        });
      }
    })
  }

  openStudioLink(studioId) {
    var url = "/studios/edit/" + studioId;
    window.open(url, "_blank");
  }

  contact_studio(id: string) {
    this.loadingContactStudio = true;
    this.studioService.studio_list_per_reservation(id).subscribe(res => {
      res.map(studio => {
        this.contactStudioList.push(studio.contact);
      })
      this.loadingContactStudio = false;
    },err=>{
      this.loadingContactStudio = false;
    })
  }


}
