import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, UntypedFormArray, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StudioService } from './../../../services/studio/studio.service';
import { environment } from './../../../../environments/environment';
import Swal from 'sweetalert2';
import { OuvertureService } from './../../../services/ouverture/ouverture.service';
import { HorairesService } from './../../../services/horaires/horaires.service';
import { LinksService } from './../../../services/links/links.service';
import { TypeService } from './../../../services/type/type.service';
import { PrestationsService } from './../../../services/prestations/prestations.service';
import { FormService } from './../../../services/form/form.service';
import moment from 'moment';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Location } from '@angular/common';
import { Studio } from './../../../classes/studio.model';

export interface webflowVilles {
  name: string
  id: string
}

@Component({
  selector: 'app-create-studio',
  templateUrl: './create-studio.component.html',
  styleUrls: ['./create-studio.component.css']
})
export class CreateStudioComponent implements OnInit {

  generate_chatgpt_description: boolean = false;
  studio: any;
  avatar = environment.defaultAvatar;
  // forms
  Form: UntypedFormGroup;
  prestationForm: UntypedFormGroup;
  horaireForm: UntypedFormGroup;
  socialMediaForm: UntypedFormGroup;
  submitted = false;
  newStudio = false;
  listStudio = [];
  data: any[][] = [];
  loading = true;
  // images
  files: Array<File> = [];
  images = new Array;

  typeStudio: any;

  days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
  social = [
    { name: "Facebook", link: "https://www.Facebook.com" }
    , { name: "Twitter", link: "https://www.Twitter.com" }
    , { name: "Instagram", link: "https://www.Instagram.com" }
    , { name: "Tiktok", link: "https://www.Tiktok.com" }
    , { name: "Youtube", link: "https://www.Youtube.com" }
  ];

  //google place (paris france by default)
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    var addressComponents = address;
    this.getAdress(addressComponents);
  }

  constructor(private location: Location, private ouvertureService: OuvertureService, private horaireService: HorairesService, private linksService: LinksService, private typeService: TypeService, private prestationService: PrestationsService, private router: Router, private studioService: StudioService, private fb: UntypedFormBuilder, private spinner: NgxSpinnerService, private formService: FormService) {
    this.Form = this.fb.group({
      name: ['', Validators.required],
      adresse: ['', Validators.required],
      city: [''],
      country: [''],
      lat: ['', Validators.required],
      lng: ['', Validators.required],
      postal_code: [''],
      route: [''],
      phone: ['', Validators.required],
      email: [''],
      siren: [''],
      description: [''],
      web_site: [''],
      isSponsored: [false],
      is_draft: [false],
      commission: 0,
      minhours_day: 1,
      has_smok: [false],
      has_internet: [false],
      has_cabin: [false],
      has_rest_room: [false],
      has_kitchen: [false],
      is_enregistrement: [false],
      is_mix: [false],
      is_mastering: [false],
      is_beatmaking: [false],
      has_bathroom: [false]

      //minhours_semiday: 0
    })
    this.prestationForm = this.fb.group({
      array: this.fb.array([], [Validators.required])
    })
    this.horaireForm = this.fb.group({
      array: this.fb.array([])
    })
    this.socialMediaForm = this.fb.group({
      array: this.fb.array([])
    })
  }

  get f() { return this.Form.controls; }

  ngOnInit() {
    this.fillPrestation();
    this.addDays();
  }

  catchFormErrors(form: UntypedFormGroup) {
    let errors = [];
    for (let el in form.controls) {
      if (form.controls[el].errors) {
        errors.push(el);
      }
    }
    return errors;
  }

  catchFormChildrenErrors(form: UntypedFormGroup) {
    let errors = []
    let requiredType = [];
    for (let i = 0; i < form.controls.array['controls'].length; i++) {
      let controlList = form.controls.array['controls'][i];
      for (let el in controlList.controls) {
        if (controlList.controls[el].errors) {
          if (!requiredType.includes(controlList.value.name) && el != "tarif") {
            requiredType.push(controlList.value.name)
          }
          if (!errors.includes(el)) {
            errors.push(el);
          }
        }
      }
    }
    return { errors: errors, types: requiredType };
  }
  onSubmit() {
    let errorList: any;
    let requiredType: Array<any>;
    let errorsText = "";
    this.submitted = true;
    let hasError = false;

    if ((this.Form.invalid || this.files.length < 1 || this.titleHasSymbol(this.Form.value.name))) {
      errorList = this.catchFormErrors(this.Form);
      if (errorList.includes('name') || errorList.includes('description') || errorList.includes('phone') || errorList.includes('email')) {
        errorsText += '- il faut remplir les champs vides !!<br>';
      }
      if (this.files.length < 1) {
        errorsText += '- il faut uploader une image au moins<br>';
      }

      if (errorList.includes('lat')) {
        errorsText += '- il faut selectionner l\'adresse du studio qui apparait dans la recherche<br>';
      }
      if (this.titleHasSymbol(this.Form.value.name)) {
        errorsText += '- Le nom du studio ne doit pas contenir de symbols<br>';
      }

      Swal.fire('ATTENTION', errorsText, 'error');
      return
    }
    if ((this.horaireForm.invalid || this.prestationForm.invalid)) {
      errorList = this.catchFormErrors(this.prestationForm);
      requiredType = this.catchFormChildrenErrors(this.prestationForm)['types'];
      this.prestationForm.controls.array['controls'].map(x => {
        if (x.errors && x.errors.message) {
          errorsText += x.errors.message + ' pour le type <strong>' + x.value.name + "</strong><br>";
          return
        }
      })

      if (errorList.includes('array')) {
        errorsText += '- Veuillez sélectionner au moins un type !!<br>';
      } else {
        errorList = this.catchFormChildrenErrors(this.prestationForm)['errors'];
      }

      if (requiredType.length > 0) {
        errorsText += '- Veuillez sélectionner au moins une prestation pour ' + requiredType.toString() + '<br>';
      }

      if (this.horaireForm.invalid) {
        errorsText += '- Veuillez vérifier les horaires<br>';
      }

      if (errorList.includes('tarif')) {
        errorsText += '- Veuillez remplir un tarif<br>';
      }
      Swal.fire('ATTENTION', errorsText, 'error');
      return
    }
    if (this.socialMediaForm.invalid) {
      Swal.fire('ATTENTION', 'Veuillez remplir les champs vides (Réseaux sociaux)', 'error');
      return
    }
    this.spinner.show();
    let studio = new Studio(this.Form.value);
    // create studio
    this.studioService.create(studio, this.files).subscribe(studio => {
      this.horaireForm.value.array.map(x => x.studio = studio.id);
      this.prestationForm.value.array.map(x => x.studio = studio.id);
      this.socialMediaForm.value.array.map(x => x.studio = studio.id);

      //   // create ouverture
      let listHoraires = this.horaireForm.value.array.map(obj => {
        let hours = obj.horaires;
        obj.horaires = [];
        return hours;
      });

      this.ouvertureService.create(this.horaireForm.value).subscribe(ouverture => {
        let listHoraireToAdd = [];

        if (ouverture) {

          this.linksService.create(this.socialMediaForm.value).subscribe(link => {
          }, err => {
            hasError = true;
            console.log("error links");
            console.log(err);
            this.spinner.hide();
          })

          this.typeService.create(this.prestationForm.value).subscribe(res => {
            Swal.fire("Félicitation", "Studio ajouté avec succès", "success");
            this.router.navigate(['/studios']);
            this.spinner.hide();
          }, err => {
            hasError = true;
            console.log("err prestations"); console.log(err);
            this.spinner.hide();
          })

        }
      }, err => {
        hasError = true;
        console.log(err); console.log("ouverture error");
        this.spinner.hide();
      })

    }, err => {
      console.log(this.Form);
      hasError = true;
      console.log(err);
      this.spinner.hide();
      let errMsgs = err?.error?.data?.errors;
      let message = `${err.error?.message}: ${errMsgs?.map(m => m?.message || m)}`;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une érreur est survenue",
        footer: `<p class="alert alert-danger">${message}</p>`
      });
    })
    if (hasError) {
    } else {
      // Swal.fire("Félicitation", "Studio ajouté avec succès", "success");
    }
  }

  uploadFile(event) {
    var reader = new FileReader();
    this.files.push(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.images.push(reader.result);
    }
  }

  removeImage(index: number) {
    Swal.fire({
      text: 'Voulez-vous supprimer cette photo',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: `Supprimer`,
      cancelButtonText: `Annuler`,
      cancelButtonColor: `#FF0000`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.files.splice(index, 1);
        this.images.splice(index, 1);
        Swal.fire('Supression', 'Effectuée avec succès', 'success')
      }
    })
  }
  fillPrestation() {
    this.prestationService.get_prestations().subscribe(async res => {
      const arr = res
      const ids = arr.map(o => o.type_prestation)
      this.typeStudio = arr.filter(({ type_prestation }, index) => !ids.includes(type_prestation, index + 1))
    })
  }
  // (prestations & type prestations)
  createArray(name: string) {
    return this.fb.group({
      name: name,
      prestations: new UntypedFormArray([], [Validators.required]),
      tarif: ['', Validators.required],
      tarif_promo: [''],
      studio: null,
    }, { validators: this.checkTarif('tarif', 'tarif_promo') });
  }

  getArray(form) {
    return form.controls.array.controls;
  }

  getPrestation(form) {
    return form.controls.prestations.controls;
  }

  addArray(form: UntypedFormGroup, name: string) {
    const control = <UntypedFormArray>form.get('array');
    control.push(this.createArray(name));
  }

  deleteArray(form: UntypedFormGroup, i: number) {
    const control = <UntypedFormArray>form.get('array');
    control.removeAt(i);
  }

  addPrestation(form: UntypedFormGroup, i: number, value: string) {
    const control = <UntypedFormArray>form.get('array')['controls'][i].get('prestations');
    control.push(new UntypedFormControl(value));
  }

  deletePrestation(form: UntypedFormGroup, arrayIndex: number, index: number) {
    const control = <UntypedFormArray>form.get('array')['controls'][arrayIndex].get('prestations');
    control.removeAt(index);
  }

  change(form: UntypedFormGroup, event: any, name: string) {
    let index = this.arrayIndex(form, name);
    if (event.target.checked) {
      this.addPrestation(form, index, event.target.value);
    } else {
      this.deletePrestation(form, index, this.prestationIndex(form, index, event.target.value))
    }
  }

  selectType(form: UntypedFormGroup, event: any) {
    if (event.target.checked) {
      this.prestationService.get_prestation_by_type(event.target.value).subscribe(prestation => {
        this.data[event.target.value] = prestation.map(items => {
          return items;
        })
      })
      this.addArray(form, event.target.value);
    }
    else {
      this.deleteArray(form, this.arrayIndex(form, event.target.value));
      this.data[event.target.value] = [];
    }
  }

  arrayIndex(form: UntypedFormGroup, name: string) {
    let array: Array<any> = form.value['array'];
    return array.findIndex(x => x.name == name);
  }

  prestationIndex(form: UntypedFormGroup, arrayIndex: number, name: string) {
    let array: Array<any> = form.value['array'][arrayIndex]['prestations'];
    return array.findIndex(x => x == name);
  }
  //horaire
  createDay(day: any) {
    return this.fb.group({
      day: day,
      open: true,
      full_day: true,
      studio: null,
      horaires: new UntypedFormArray([])
    }
      , { validators: this.fullDateValidator('open', 'full_day', 'horaires') }
    )
  }

  addDays() {
    const control = <UntypedFormArray>this.horaireForm.get('array');
    for (let i = 1; i < 8; i++) {
      let day = (i != 7) ? i : 0;
      control.push(this.createDay(day));
    }
  }

  createHoraires() {
    return this.fb.group({
      heur_debut: [null],
      heur_fin: [null],
      ouverture: null
    }
      , { validators: this.timeValidator('heur_debut', 'heur_fin') }
    )
  }

  addHoraire(index: number) {
    const control = <UntypedFormArray>this.horaireForm.get('array')['controls'][index].get('horaires');
    control.push(this.createHoraires());
  }

  getHoraires(form: any) {
    return form.controls.horaires.controls;
  }

  deleteSeletedHoraire(arrayIndex: number, index: number) {
    const control = <UntypedFormArray>this.horaireForm.get('array')['controls'][arrayIndex].get('horaires');
    control.removeAt(index)
  }

  deleteHoraires(arrayIndex: number) {
    const control = <UntypedFormArray>this.horaireForm.get('array')['controls'][arrayIndex].get('horaires');
    let length = control.value.length;
    for (let i = length - 1; i >= 0; i--) {
      control.removeAt(i);
    }
  }

  onFullDayChange(event: any, i: number) {
    if (event.target.checked) {
      this.deleteHoraires(i);
    }
  }

  isChecked(i: number, form: UntypedFormGroup, control: string) {
    let state = form.value['array'][i][control];
    return state;
  }

  // end step 2

  createLinks(name: string) {
    return this.fb.group(
      {
        name: name,
        checked: true,
        link: [null, Validators.required],
        studio: null
      })
  }

  addLink(form: UntypedFormGroup, name: string) {
    const control = <UntypedFormArray>form.get('array');
    control.push(this.createLinks(name));
  }

  selectLink(form: UntypedFormGroup, event: any) {
    if (event.target.checked) {
      this.addLink(form, event.target.value);
    }
    else {
      this.deleteArray(form, this.arrayIndex(form, event.target.value));
    }
  }

  private timeValidator(beginHour: string, finalHour: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as UntypedFormGroup
      const valueOfBeginHour = formGroup.get(beginHour)?.value
      const valueOffinalHour = formGroup.get(finalHour)?.value

      let debut: any = moment(valueOfBeginHour, 'HH:mm'); //=> '2013-03-10T01:30:00-05:00'
      let fin: any = moment(valueOffinalHour, 'HH:mm'); //=> '2013-03-10T01:30:00-05:00'

      let difference = fin.diff(debut);

      if (difference > 0) {
        return null
      } else {
        return { valuesDoNotMatch: true }
      }
    }
  }

  private fullDateValidator(open: string, full_day: string, horaires: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as UntypedFormGroup
      const fullDayValue = formGroup.get(full_day)?.value
      const horaireValue = formGroup.get(horaires)?.value
      const openValue = formGroup.get(open)?.value

      if (!openValue || fullDayValue || horaireValue.length > 0) {
        return null
      } else {
        return { valuesDoNotMatch: true }
      }
    }
  }
  getAdress(addressComponents) {
    var city, route, number, country, postal, shourt, departement = "";

    this.Form.patchValue({
      lat: addressComponents.geometry.location.lat().toString(),
      lng: addressComponents.geometry.location.lng().toString(),
      adresse: addressComponents.formatted_address
    });
    addressComponents.address_components.filter(function (component) {
      switch (component.types[0]) {
        case "route": // route
          route = component.long_name;
          return true;
        case "locality": // city
          city = component.long_name;
          return true;
        case "street_number": // street number
          number = component.long_name;
          return true;
        case "country": // country (logn and short name)
          country = component.long_name;
          shourt = component.short_name;
          return true;
        case "administrative_area_level_2": // country (logn and short name)
          departement = component.long_name;
          return true;
        case "postal_code": // postal code
          postal = component.long_name;
          return true;
        default:
          return false;
      }
    });
    // affect adresses to my variables
    this.Form.patchValue({
      country: (country != undefined) ? country : "",
      city: (city != undefined) ? city : "",
      postalCode: (postal != undefined) ? postal : "",
      route: (route != undefined) ? route : ""
    });
  }

  back() {
    this.location.back();
  }

  initDescription() {
    this.f.description.setValue("");
  }

  generateDescriptionChatGpt() {
    let descriptionChatGPT = this.f.description.value;
    if (descriptionChatGPT.length > 0) {

      this.generate_chatgpt_description = true;
      this.spinner.show();
      this.studioService.generate_description_chatgpt(descriptionChatGPT).subscribe((res) => {
        if (typeof res === 'object') {
          if (res["error"].code == "context_length_exceeded") {
            Swal.fire("Attention", "Veuillez réduire le nombre de caractères dans votre message \n ( nombre maximum est 8000 )", "info");
          } else {
            //Swal.fire("Erreur", "Une érreur est survenue", "error");
            let msg = res["error"]?.message;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Une érreur est survenue",
              footer: `<p class="alert alert-danger">${msg}</p>`
            });

          }
        } else {
          this.f.description.setValue(res);
        }
      }, (err) => {
        if (err.status === 200) {
          this.f.description.setValue(err.error.text);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Une érreur est survenue",
            footer: `<p class="alert alert-danger">${JSON.stringify(err.error)}</p>`
          });
        }
      }).add(() => {
        this.generate_chatgpt_description = false;
        this.spinner.hide();
      });
    } else {
      Swal.fire("Attention", "veuillez saisir un text", "info");
    }
  }

  private checkTarif(tarif: string, tarif_promo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as UntypedFormGroup
      const tarif_promoValue = Number(formGroup.get(tarif_promo)?.value);
      const tarifValue = Number(formGroup.get(tarif)?.value);

      if ((tarifValue > tarif_promoValue) && tarif_promoValue >= 0) {
        return null
      } else if (tarif_promoValue < 0) {
        return { message: "- la valeur du tarif promo doit être supérieur ou égale à zéro" }
      } else {
        return { message: "- la valeur du tarif promo doit être inférieur que le tarif" }
      }
    }
  }

  // dont't allow studio title to has symbols to prevent errors on webflow slug
  titleHasSymbol(title: string) {
    var format = /[^a-zA-Z0-9À-ÿ\s]/;
    return format.test(title);
  }

}
