import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './../../../../app/services/auth/auth.service';
import { TokenStorageService } from './../../../../app/services/auth/token-storage.service';


import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  Form: UntypedFormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  path = "";
  submitted = false;
  submitForgotPassword = false;

  constructor(private spinner:NgxSpinnerService,public router: Router, private fb: UntypedFormBuilder, private authService: AuthService, private tokenStorage: TokenStorageService) {
    this.Form = this.fb.group({
      identifier: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      email: [''],
    })
  }

  ngOnInit(): void {
    
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigate(['/'])
    }
  }

  get f() {
    return this.Form.controls;
  }

  onSubmit(): void {

      this.submitted = true;
      if (this.Form.invalid) {
        Swal.fire('Error', 'Veuillez vérifier vos informations', 'error')
        return
      }
      this.spinner.show();
      this.authService.login(this.Form.value.identifier, this.Form.value.password).subscribe(
        data => {
          if(data.user.role.type!="super_admin"){
            Swal.fire('Error', 'Vous n\'êtes pas un admin', 'error');
          }else{
            this.tokenStorage.saveToken(data.jwt);
            this.tokenStorage.saveUser(data.user);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.router.navigate(['/'])
          }
          this.spinner.hide();
        },
        err => {
          if (err.error.data[0].messages[0].id == 'Auth.form.error.invalid') {
            Swal.fire('Error', 'Email ou mot de passe invalide', 'error')
          }
          else if (err.error.data[0].messages[0].id == 'Auth.form.error.confirmed') {
            Swal.fire('Error', 'Votre compte n\'est pas activé, Veuillez vérifier votre boite de réception', 'error')
          }
          else {
            Swal.fire('Error', 'Une érreur est survenue', 'error');
          }
          this.spinner.hide();
          this.isLoginFailed = true;
        }
      );
    
    
  }

}
