import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';
import { Studio } from './../../classes/studio.model';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class StudioService {
  endPoint = 'studios';
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create(studio: Studio, medias: Array<File>): Observable<any> {
    let data = studio;
    const formData = new FormData();
    medias.forEach(element => {
      formData.append("files.medias", element);
    });

    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + 'studios', formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  contactStudio(array: any): Observable<any> {
    return this.http.post(environment.api + 'contact-studios/add', array, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  studio_list_per_reservation(idReservation: string): Observable<any> {
    return this.http.get(environment.api + 'contact-studios?reservation=' + idReservation, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  assignUser(studioId: string, user: any): Observable<any> {
    return this.http.put(environment.api + this.endPoint + '/' + studioId, { user: user }, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": 'application/json' }
    });
  }
  update(studio: Studio, medias: Array<File>): Observable<any> {
    let data = studio
    const formData = new FormData();
    medias.forEach(element => {
      formData.append("files.medias", element);
    });

    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + this.endPoint + '/' + studio.id, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  gets_by_user_id(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?user=" + this.tokenStorageService.getUser().id + "&_sort=createdAt:DESC");
  }

  get_by_id(studioId: string): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/" + studioId, { headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() } });
  }

  get_by_filter(type: string, lat: string, lng: string, min_distance: number, max_distance: number, min_tarif: number, max_tarif: number, prestation: string, name: string, startDate: any, endDate: any): Observable<any> {
    if (min_distance) {
      min_distance *= 1000;
    }
    if (max_distance) {
      max_distance *= 1000;
    }

    let url = environment.api + this.endPoint + `/?type=${type.toUpperCase()}&lat=${lat}&lng=${lng}&min_distance=${min_distance.toString()}&max_distance=${max_distance.toString()}&min_tarif=${min_tarif.toString()}&max_tarif=${max_tarif.toString()}&prestations=${prestation}`;
    (endDate) ? url += `&start_date=${startDate}&end_date${endDate}` : url;
    (name != undefined && name) ? url = environment.api + this.endPoint + `/?types.name=${type.toUpperCase()}&_q=${name}` : url;

    return this.http.get(url);
  }

  set_commission(form: any) {
    return this.http.post(environment.api + this.endPoint + '/update', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  set_commission_for_all_studios(value: any) {
    return this.http.post(environment.api + this.endPoint + '/get/updateCommissionForStudios', { "commission": value }, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }


  search(value: string): Observable<any> {
    let url = environment.api + this.endPoint + "/all?_q=" + value + "&is_deleted=false";
    return this.http.get(url, { headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() } });
  }

  get_alls(filter: string): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/all" + filter + "&_limit=-1", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  /*
  get_last(number: number) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "/all?_limit=" + number + "&_sort=createdAt:DESC&is_deleted=false", {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
    return promise
  }
  */
  get_last(number: number): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/all?_limit=" + number + "&_sort=createdAt:DESC&is_deleted=false", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  count(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/count?is_deleted=false", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  get(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/all?_limit=-1", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  getVillesWebflow(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/getVillesWebflow", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    })
  }

  delete_by_id(studioId: string, state: boolean): Observable<any> {
    let data = {
      is_deleted: state,
    }
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + 'studios/' + studioId, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  generate_description_chatgpt(description: string) {
    return this.http.post(environment.api + 'studios/get/generateDescriptionChatGpt', { "description": description }, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  return_empty_if_null(value: any) {
    return (value || value == 0) ? value.toString() : '';
  }

  get_studios_arround(type: string, startDate?: any, endDate?: any, lat?: string, lng?: string, min_tarif?: number, max_tarif?: number, min_distance?: number, max_distance?: number) {
    const promise = new Promise((resolve, reject) => {
      if (min_distance) {
        min_distance *= 1000;
      }
      if (max_distance) {
        max_distance *= 1000;
      }

      let extraParams = `&min_distance=${this.return_empty_if_null(min_distance)}&max_distance=${this.return_empty_if_null(max_distance)}&min_tarif=${this.return_empty_if_null(min_tarif)}&max_tarif=${this.return_empty_if_null(max_tarif)}`
      let url = environment.api + `studios/?type=${type.toUpperCase()}&is_draft=false&is_deleted=false&user_null=false&start_date=${startDate}&end_date=${endDate}&lat=${lat}&lng=${lng}` + extraParams;

      this.http.get(url)
        .toPromise()
        .then(
          res => {
            resolve(res);

          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  get_studios_arround_for_reservation(type: string, startDate?: any, endDate?: any, lat?: string, lng?: string, city?: string, min_distance?: number, max_distance?: number, min_price?: number, max_price?: number, webFlowParams?: string) {
    const promise = new Promise((resolve, reject) => {
      if (min_distance) {
        min_distance *= 1000;
      }
      if (max_distance) {
        max_distance *= 1000;
      }

      let priceParams = `&min_tarif=${this.return_empty_if_null(min_price)}&max_tarif=${this.return_empty_if_null(max_price)}`;
      let localisationParams = (lat && lng) ? `&lat=${this.return_empty_if_null(lat)}&lng=${this.return_empty_if_null(lng)}&city=${this.return_empty_if_null(city)}&min_distance=${this.return_empty_if_null(min_distance)}&max_distance=${this.return_empty_if_null(max_distance)}` : '';
      let url = environment.api + `studios/?type=${type.toUpperCase()}&from_backoffice=true&user_null=false&start_date=${startDate}&end_date=${endDate}` + localisationParams + priceParams + '&' + webFlowParams;

      this.http.get(url)
        .toPromise()
        .then(
          res => {
            resolve(res);

          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


}