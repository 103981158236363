import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, query, stagger, state } from "@angular/animations";
import { DataTableDirective } from 'angular-datatables';
import moment from "moment";
import { Subject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ReservationService } from "../../../app/services/reservation/reservation.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: "app-reservations",
  templateUrl: "./reservations.component.html",
  styleUrls: ["./reservations.component.css"],
  animations: [
    trigger('rowAnimation', [
      state('true', style({ opacity: 0 })),
      state('false', style({ opacity: 1 })),
      transition('true => false', animate(300)),
      transition('false => true', animate(0)),
      transition('* => *', [
        // this hides everything right away
        query(':enter', style({ opacity: 0 }), { optional: true }),

        // starts to animate things with a stagger in between
        // query(':enter', stagger('100ms', [
        //   animate('1s', style({ opacity: 1 }))
        // ])),
        query(':enter', animate('1s', style({ opacity: 1 })), { optional: true })
      ])
    ])]
})
export class ReservationsComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  Form: UntypedFormGroup;
  list = [];
  loading = false;
  rendering = false;
  type = "Pré-Réservation";
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    order: [[5, 'desc'],[2, 'desc']],
    responsive: true,
    columnDefs: [
      {
        "targets": 2, "render": function (data, type, row) {
          if (type === 'sort') {
            return moment(data, "DD-MM-YYYY HH:mm").valueOf();
          }
          return data;
        }
      },
      {
        "width": "100px", "targets": 5, "render": function (data, type, row) {
          if (type === 'sort') {
            return moment(data, "DD-MM-YYYY HH:mm").valueOf();
          }
          return data;
        }
      },

    ]
  };

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private reservationService: ReservationService,
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder
  ) {
    this.Form = this.fb.group({
      search: "",
      type: "Pré-Réservation",
    });
  }

  ngOnInit(): void {
    let cache = localStorage.getItem("reservations");
    if (cache && cache !== 'undefined') {
      this.list = JSON.parse(cache);
      this.hideSpinner()
    }
    this.type = localStorage.getItem("filter_type");
    if (!this.list || !this.type || this.type === "tous") {
      this.loadAll();
    }
    else {
      this.load(this.type);
    }
  }

  getList() {
    if (this.type == "tous") {
      this.loadAll();
    } else {
      this.load(this.type);
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  private loadAll() {
    this.showSpinner();
    this.reservationService.get().subscribe((res) => {
      this.list = res.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      localStorage.setItem("reservations", JSON.stringify(this.list.slice(0, environment.cach_size)));
      localStorage.setItem("filter_type", "tous");
      this.type = "tous";
      this.rerender();
    }).add(() => {
      this.hideSpinner();
    });
  }

  // 2021-06-17 16:00
  showDateHour(date: any) {
    return moment(date).utcOffset(0).format("DD-MM-YYYY HH:mm");
  }

  hideSpinner() {
    this.spinner.hide();
    this.loading = false;
  }
  showSpinner() {
    //this.spinner.show();
    this.loading = true;
  }

  onSubmit() {
    this.showSpinner();
    this.reservationService
      .search(this.Form.value.search)
      .subscribe((res) => {
        this.list = res;
        this.rerender();
      })
      .add(() => {
        this.hideSpinner();
      });
  }

  selectType() {
    this.load(this.Form.controls["type"].value);
  }

  getPaymentClass(item) {
    if (!item.payment) {
      return 'bg-danger';
    } else {
      return item.payment?.type.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }
  }

  getPaymentType(item) {
    let type = (item.payment) ? (item.payment.type == 'waiting') ? (item.name == 'hotline' && this.type=='hotline') ? 'hotline' : 'En attente de paiement' : item.payment.type : 'N/A';
    return type.toUpperCase();
  }

  private load(type: any) {
    this.type = type;
    let filters = (!type || type == "tous") ? "" : (type == "hotline") ? "&name=" + type + "&payment.type=" + type + "&payment.type=waiting" : "&payment.type=" + type;
    if (type != "Pré-Réservation") {
      filters += "&is_deleted=false"
    }
    this.showSpinner();
    localStorage.setItem("filter_type", type);
    this.reservationService.getByPaymentType(filters).subscribe((res: any) => {
      this.list = res.filter(x => x.user != null);
      localStorage.setItem("reservations", JSON.stringify(this.list.slice(0, 20)));
      this.rerender();
    }).add(() => {
      this.hideSpinner();
    });
  }

  rerender(): void {
    this.rendering = true;
    if (!this.dtElement) {
      setTimeout(() => {
        this.rendering = false;
      }, 100);
    }
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
      setTimeout(() => {
        this.rendering = false;
      }, 50);
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
