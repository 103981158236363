// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  cach_size: 100,
  api: 'https://api.dev.studiomap.fr/',
  base_api: 'https://api.dev.studiomap.fr',
  webUrl: 'https://dev.studiomap.fr/',
  webFlowLink: 'https://studioma.webflow.io',
  defaultAvatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrkeXEvyj-XjzT410bqMziLirLmtUuoEb-rJzBmOrzVP41rlx-m-NmaCoIeaTxW9-fEWU&usqp=CAU",
  freelancePrestations: [{ name: "Photographe / Réalisateur" }, { name: "Graphiste / Designer" }, { name: "Beatmaker / Ingénieur son" }, { name: 'Modèle / Figurant' }],
  studioPrestations: [
    { value: "ENREGISTREMENT", name: "Studio d’enregistrement" }
    , { value: "PHOTOGRAPHIE", name: "Studio photo & vidéo" }
    // , { value: "REPETITION", name: "Studio de répétition" }
    // , { value: "DANSE", name: "Studio de danse" }
  ],
  recaptcha: {
    siteKey: '6LfIdjEpAAAAAHN8aFJpO-IMj18GjxbutyoTtclZ',
  },
};
