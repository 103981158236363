import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from '../../../services/products/products.service';
import { StripeService } from '../../../services/stripe/stripe.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-code-promo',
  templateUrl: './add-code-promo.component.html',
  styleUrls: ['./add-code-promo.component.css']
})
export class AddCodePromoComponent implements OnInit {
  submitted = false;
  Form: UntypedFormGroup;
  durations=["once","repeating","forever"];
  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private stripeService: StripeService,
  ) {
    this.Form = fb.group({
      name: ["", Validators.required],
      duration: ["once", Validators.required],
      percent_off:  ["", Validators.required],
    })
  }

  get f() {
    return this.Form.controls;
  }

  selectDuration(value:string){
    if(value=='repeating'){
      this.Form.addControl("duration_in_months",new UntypedFormControl("",[Validators.required]))
    }else{
      this.Form.removeControl("duration_in_months");
    }
  }


  ngOnInit(): void {
  }

  onSubmit() {
    const values = this.Form.value;
    this.submitted = true;
    if (this.Form.invalid || this.Form.value.percent_off> 100 || this.Form.value.percent_off<0) {
      if(this.Form.value.percent_off> 100 || this.Form.value.percent_off<0){
        Swal.fire('Erreur', 'le pourcentage doit être entre 0 et 100', 'error');
      }else{
        Swal.fire('Erreur', 'Veuillez vérifier les champs vides', 'error');
      }
      return
    }
    this.spinner.show();
    this.stripeService.createCoupon(values).then((coupon:any) => {
      Swal.fire('Félicitation', 'coupon créé avec succès', 'success');
      this.router.navigate(["/coupon"]);
    },err=>{
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }).finally(()=>{
      this.spinner.hide();
    })
  }

  translate(value: string) {
    let text = "";
    switch (value) {
      case "once":
        text = "Une fois";
        break;
      case "repeating":
        text = "Périodique";
        break;
      case "forever":
        text = "permanent";
        break;
    }
    return text;
  }
}
