import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../services/auth/auth.service';
import { TokenStorageService } from '../../../services/auth/token-storage.service';
import { FormService } from '../../../services/form/form.service';
import { ProfileService } from '../../../services/profile/profile.service';
import { StripeService } from '../../../services/stripe/stripe.service';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  Form: UntypedFormGroup;
  submitted = false;
  path = "";
  codeParrainage: any;
  type_service = environment.freelancePrestations;
  //google place (paris france by default)
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    var addressComponents = address;
    this.getAdress(addressComponents);
  }
  constructor(private location: Location, private route: ActivatedRoute, private formService: FormService, private spinner: NgxSpinnerService, private fb: UntypedFormBuilder, private profileService: ProfileService, private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router, private stripeService: StripeService) {
    this.Form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      adresse: [''],
      type_service: [this.type_service[0].name],
      lat: [''],
      lng: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      type: ['artiste', Validators.required],
      full_name: ['', Validators.required],
      phone: ['', Validators.required],
      all_city: [false],
      isSponsored: [false],
      is_draft: [false],
    }, { validators: this.checkPasswords })
  }
  //validator submit
  get f() { return this.Form.controls; }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;
    if (this.Form.value.type == 'freelance') {
      this.formService.requireControl(this.Form, 'adresse');
    } else {
      this.formService.facultatifControl(this.Form, 'adresse');
      this.formService.emptyControl(this.Form, 'type_service');
      this.formService.emptyControl(this.Form, 'adresse');
      this.formService.setControlValue(this.Form, 'all_city', null);
    }

    if (this.Form.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }
    this.spinner.show();
    this.authService.register(this.Form.value.email, this.Form.value.email, this.Form.value.password, this.Form.value.type).subscribe(res => {
      this.profileService.create_profile(res.user.id, this.Form.value.email, this.Form.value.full_name, this.Form.value.phone, this.Form.value.adresse, this.Form.value.lng, this.Form.value.lat, this.Form.value.type_service, this.Form.value.all_city, this.Form.value.isSponsored, this.Form.value.is_draft).subscribe(profile => {
        this.stripeService.create_customer(res.user.id, this.Form.value.email, this.Form.value.full_name).subscribe()
        res.user['profile'] = profile;
        Swal.fire('Félicitation', 'le nouveau compte a été créé avec succès, un couriel d\'activation a été envoyé à l\'adresse mail que vous avez saisi', 'success');
        this.router.navigate(["/utilisateurs"]);
      }, err => {
        console.log(err);
      })
    }, err => {
      console.log(err);
      if (err.error.message[0].messages[0].message == "Email is already taken.") {
        Swal.fire('Erreur', 'Adresse email déjà utilisée', 'error')
      } else if (err.error.message[0].messages[0].message)
        Swal.fire('Erreur', err.error.message[0].messages[0].message, 'error')

    }).add(() => {
      this.spinner.hide();
    })
  }

  select() {
    this.Form.controls["type_service"].setValue(this.type_service[0].name);
  }

  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { notSame: true }
  }

  getAdress(addressComponents) {
    this.Form.patchValue({
      lat: addressComponents.geometry.location.lat().toString(),
      lng: addressComponents.geometry.location.lng().toString(),
      adresse: addressComponents.formatted_address
    });
  }

  back() {
    this.location.back();
  }

}
