export const environment = {
  production: true,
  cach_size: 100,
  api: 'https://api.studiomap.fr/',
  base_api: 'https://api.studiomap.fr',
  webUrl: 'https://studiomap.app/',
  webFlowLink: 'https://studiomap.fr',
  defaultAvatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrkeXEvyj-XjzT410bqMziLirLmtUuoEb-rJzBmOrzVP41rlx-m-NmaCoIeaTxW9-fEWU&usqp=CAU",
  freelancePrestations: [{ name: "Photographe / Réalisateur" }, { name: "Graphiste / Designer" }, { name: "Beatmaker / Ingénieur son" }, { name: 'Modèle / Figurant' }],
  studioPrestations: [
    { value: "ENREGISTREMENT", name: "Studio d’enregistrement" }
    , { value: "PHOTOGRAPHIE", name: "Studio photo & vidéo" }
    // , { value: "REPETITION", name: "Studio de répétition" }
    // , { value: "DANSE", name: "Studio de danse" }
  ],
  recaptcha: {
    siteKey: '6LfIdjEpAAAAAHN8aFJpO-IMj18GjxbutyoTtclZ',
  },
};
