import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  endPoint = 'payments/';
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  update(payment_id: string,data:any): Observable<any> {
    return this.http.put(environment.api + this.endPoint + payment_id, data,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }
  
  create(data:any): Observable<any> {
    return this.http.post(environment.api + this.endPoint ,  data,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }



}
