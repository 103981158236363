import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TagsService } from './../../services/tags/tags.service';
import { MediasService } from './../../services/medias/medias.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  Form: UntypedFormGroup;
  submitted = false;
  selectedItem: any;
  file: any;
  image: any;
  imagesToDelete: any;
  action: string;
  tagId: string;
  pages = [
    {
      page: "-----",
      value: "-----",
      items: ['-----'],
      description: "",
      image: "",
    },
    {
      page: "Accueil",
      value: "home",
      items: ['-----'],
      description: "",
      image: "",
    },
    {
      page: "Prestation creative",
      value: "prestation-creative",
      items: ['-----', 'photographie', 'video', 'motion-design', 'modele'],
      description: "",
      image: "",
    },
    {
      page: "Réserver studio",
      value: "reserver-studio",
      items: ['-----'],
      description: "",
      image: "",
    },
    {
      page: "Offres",
      value: "offres",
      items: ['-----', 'photographe', 'videaste', 'designer', 'modele'],
      description: "",
      image: "",
    },
    {
      page: "freelances",
      value: "freelances",
      items: ['-----', 'photographie', 'video', 'motion-design', 'modele'],
      description: "",
      image: "",
    },
    {
      page: "Mentions legales",
      value: "mentions-legales",
      items: ['-----'],
      description: "",
      image: "",
    },
    {
      page: "Politique confidentialite",
      value: "politique-confidentialite",
      items: ['-----'],
      description: "",
      image: "",
    },
    {
      page: "Cookies",
      value: "cookies",
      items: ['-----'],
      description: "",
      image: "",
    },
    {
      page: "Cgu",
      value: "cgu",
      items: ['-----'],
      description: "",
      image: "",
    },
    {
      page: "Cgv",
      value: "cgv",
      items: ['-----'],
      description: "",
      image: "",
    }
  ]

  constructor(private _route: Router, private route: ActivatedRoute, private location: Location, private spinner: NgxSpinnerService, private fb: UntypedFormBuilder, private tagApi: TagsService, private mediaService: MediasService) {
    this.Form = this.fb.group({
      id: [''],
      page: ['-----', Validators.required],
      item: ['-----', Validators.required],
      description: ['', Validators.required],
      title: ['', Validators.required]
    })
  }

  get f() { return this.Form.controls; }

  ngOnInit(): void {
    if (this.route.snapshot.routeConfig.path == "tags/create") {
      this.action = "Création";
      this.selectedItem = this.pages[0].items;
    } else {
      this.action = "Modification";
      this.tagId = this.route.params["_value"].id;
      this.tagApi.getTag(this.route.params["_value"].id).subscribe(res => {
        this.Form.patchValue(res);
        this.selectedItem = this.pages.filter(p => p.value === this.Form.value.page)[0].items;
        this.image = res.image;
      }, err => {
        console.log(err);
      })
    }
  }

  onChange() {
    this.selectedItem = this.pages.filter(p => p.value === this.Form.value.page)[0].items;
  }

  uploadFile(event) {
    var reader = new FileReader();
    this.file = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.image = reader.result;
    }
  }

  back() {
    this.location.back();
  }

  onSubmit() {
    if (!this.file && !this.image) {
      Swal.fire('Attention', 'Il faut ajouter une image pour cette page', 'info');
      return
    }
    if (this.Form.invalid) {
      Swal.fire('Attention', 'Veuillez vérifier tout les champs requis', 'info');
      return
    }
    this.spinner.show();
    if (this.action == "Création") {

      this.Form.controls["item"].setValue(((this.Form.value.item == "-----") ? this.Form.value.page : this.Form.value.item));

      this.tagApi.create(this.Form.value, this.file).subscribe(res => {
        Swal.fire(this.action, 'Efféctuée avec succès', 'success');
        this._route.navigate(["/tags"]);
      }, err => {
        console.log(err);
      }).add(fin => {
        this.spinner.hide();
      });

    } else {
      this.tagApi.update(this.tagId, this.Form.value, this.file).subscribe(res => {
        Swal.fire(this.action, 'Efféctuée avec succès', 'success');
        if (this.imagesToDelete) {
          this.mediaService.delete_pictures(this.imagesToDelete).subscribe(
            res => {
              this.imagesToDelete = []; console.log(res);
            },
            err => { console.log(err) }
          )
        }
      }, err => {
        console.log(err);
      }).add(fin => {
        this.spinner.hide();
      })
    }
  }

  removeImage(id?: string) {
    Swal.fire({
      text: 'Voulez-vous supprimer cette photo',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: `Supprimer`,
      cancelButtonText: `Annuler`,
      cancelButtonColor: `#FF0000`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.file = "";
        this.image = "";
        if (id && !this.imagesToDelete) {
          this.imagesToDelete = id;
        }
        Swal.fire('Supression', 'Effectuée avec succès', 'success')
      }
    })
  }

}
