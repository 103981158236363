import { Injectable } from '@angular/core';
import { Validators, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }
  link_validator: Validators = Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

  requireControl(form: UntypedFormGroup, controle: string) {
    form.controls[controle].setValidators(Validators.required);
    form.controls[controle].updateValueAndValidity();
  }

  requireControlPercent(form: UntypedFormGroup, controle: string) {
    form.controls[controle].setValidators([Validators.required,Validators.min(0),Validators.max(100)]);
    form.controls[controle].updateValueAndValidity();
  }

  facultatifControl(form: UntypedFormGroup, controle: string) {
    form.controls[controle].clearValidators();
    form.controls[controle].updateValueAndValidity();
  }

  emptyControl(form: UntypedFormGroup, controle: string) {
    form.controls[controle].setValue('');
  }

  setControlValue(form: UntypedFormGroup, controle: string, value: any) {
    form.controls[controle].setValue(value);
  }

  isChecked(array: any, value: string) {
    let exist = array.includes(value);
    return exist;
  }
}
