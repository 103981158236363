import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  endPoint = 'stripes/';
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create_customer(user: string, email: string, full_name: string): Observable<any> {
    return this.http.post(environment.api + this.endPoint, {
      user,
      email,
      full_name,
    },
    {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    }
    );
  }

  createProduct(form:any) {
    const promise = new Promise((resolve, reject) => {
      const datas={
        "name":form.name,
        "description":form.description,
      }

      this.http.post(environment.api + this.endPoint + 'createProduct', JSON.stringify(datas), {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      })
        .toPromise()
        .then(
          res => {
            resolve(res);

          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  
  createPrice(productToken:string,form:any){
    // price
    // productToken
    const promise = new Promise((resolve, reject) => {
      const datas={
        "price": Math.round( form.price * 100 ),
        "productToken":productToken,
      }
      return this.http.post(environment.api + this.endPoint + 'createPrice',  JSON.stringify(datas), {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
      })
      .toPromise()
      .then(
        res => {
          resolve(res);
          
        },
        msg => { // Error
          reject(msg);
        }
        );
      });
      return promise;
    }


    createCoupon(form:any) {
      const promise = new Promise((resolve, reject) => {
        this.http.post(environment.api + this.endPoint + 'createCoupon', JSON.stringify(form), {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
    }
    
    listCoupons() {
      const promise = new Promise((resolve, reject) => {
        this.http.post(environment.api + this.endPoint + 'listCoupons',null, {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
    }

    deleteCoupon(couponId:string) {
      const promise = new Promise((resolve, reject) => {
        this.http.post(environment.api + this.endPoint + 'deleteCoupon',{id:couponId}, {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
    }

    checkStripeIsVerify(user: string): Observable<any> {
      return this.http.post(environment.api + 'stripes/getAccountConnect/' + user, {
      },
        {
          headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
        }
      );
    }
  }
  
  