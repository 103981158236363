import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create(form: any): Observable<any> {
    // set tarif promo to 0 if it's null
    form['array'].map(x => x.tarif_promo = (x.tarif_promo) ? x.tarif_promo : '0');

    return this.http.post(environment.api + 'types', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  delete(form: any): Observable<any> {
    return this.http.post(environment.api + 'types/delete', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  multiUpdate(form: any): Observable<any> {
    return this.http.post(environment.api + 'types/update', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }
}
