import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  create_profile(user: string, email: string, full_name: string, phone: any, adresse: string, lng: string, lat: string, type_service: string, all_city: string, isSponsored: string,
    is_draft: string,): Observable<any> {
    phone = "0" + String(phone * 1);
    return this.http.post(environment.api + 'profiles', {
      user,
      email,
      full_name,
      phone,
      adresse,
      lat,
      lng,
      type_service,
      all_city,
      isSponsored: isSponsored,
      is_draft: is_draft,
    }, httpOptions
    );
  }

  update_profile(profileId: string, data: any, avatar: File): Observable<any> {
    const formData = new FormData();
    if (avatar) {
      formData.append("files.avatar", avatar);
    }
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + 'profiles/' + profileId, formData,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }


  get_profile_by_id(profileId: string): Observable<any> {
    return this.http.get(environment.api + "profiles/" + profileId, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  get_profile_by_user_id(userId: string): Observable<any> {
    return this.http.get(environment.api + "profiles/?user=" + userId, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  getByEmail(data: any) {
    const promise = new Promise((resolve, reject) => {
      if (data.phone) {
        data.phone = "0" + String(data.phone * 1);
      }
      this.http.post(environment.api + 'profiles/getProfileByEmail', data, {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }).toPromise().then(
        res => {
          resolve(res);
        },
        msg => { // Error
          reject(msg);
        })
      })
      return promise;
  }
}
