import { Location } from '@angular/common';
import { Component, NgZone, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { StudioService } from '../../../services/studio/studio.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';
import { ProfileService } from '../../../services/profile/profile.service';
import { StripeService } from '../../../services/stripe/stripe.service';
import { ReservationService } from '../../../services/reservation/reservation.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { UtilisateurService } from '../../../services/utilisateur/utilisateur.service';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { LabelType, Options } from '@angular-slider/ngx-slider';

interface RangeSliderModel {
  minValue: number;
  maxValue: number;
  sliderOptions: Options;
}

@Component({
  selector: 'app-create-reservation',
  templateUrl: './create-reservation.component.html',
  styleUrls: ['./create-reservation.component.css']
})
export class HotlineConfirmationComponent implements OnInit {

  user: any = null;
  startDateTime: any;
  adresseForm: FormGroup;
  webFlowOptionForm: FormGroup;
  userForm: FormGroup;
  reservationForm: FormGroup;
  studiosForm: FormGroup;
  formContainer: FormGroup;

  submitted = false;
  loading = true;
  searching = false;
  step = 'userForm';
  changingStartDate = false;

  studioList = [];
  studioSuggestionList = [];

  calendar = inject(NgbCalendar);
  today: NgbDate | null = this.calendar.getToday();
  prestationTypes = environment.studioPrestations;

  //sliders
  priceSlider: RangeSliderModel = {
    minValue: 0,
    maxValue: 30,
    sliderOptions: {
      floor: 0,
      ceil: 100,
      step: 5,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '<b>Min price: </b>' + value + ' €';
          case LabelType.High:
            return '<b>Max price: </b>' + value + ' €';
          default:
            return value + ' €';
        }
      }
    }
  }

  distanceSlider: RangeSliderModel = {
    minValue: 0,
    maxValue: 50,
    sliderOptions: {
      floor: 0,
      ceil: 100,
      step: 10,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '<b>Min distance: </b>' + value + ' Km';
          case LabelType.High:
            return '<b>Max distance: </b>' + value + ' Km';
          default:
            return value + ' Km';
        }
      }
    }
  }

  //google place (paris france by default)
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;


  constructor(
    private fb: FormBuilder,
    private dateAdapter: NgbDateAdapter<string>,
    private ngZone: NgZone,
    private studioService: StudioService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private profileService: ProfileService,
    private stripeService: StripeService,
    private reservationService: ReservationService,
    private paymentService: PaymentService,
    private userService: UtilisateurService,
    private location: Location,
    private router: Router
  ) {


    this.formContainer = this.fb.group({
      userForm: this.fb.group({
        complete_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required]
      }),
      reservationForm: this.fb.group({
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        startHour: ['', Validators.required],
        endHour: ['', Validators.required],
        nbr_hours: ['1', Validators.min(1)],
        prestation_type: ['Studio d’enregistrement'],
      }),
      studiosForm: this.fb.group({
      })
    })

    this.webFlowOptionForm = this.fb.group({
      has_smok: [false],
      has_internet: [false],
      has_cabin: [false],
      has_rest_room: [false],
      has_kitchen: [false],
      is_enregistrement: [false],
      is_mix: [false],
      is_mastering: [false],
      is_beatmaking: [false],
      has_bathroom: [false]
    })

    this.adresseForm = this.fb.group({
      lat: [null, Validators.required],
      lng: [null, Validators.required],
      city: [null, Validators.required],
      adresse: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.formContainer.get('reservationForm')['controls'].startDate.setValue(this.dateAdapter.toModel(this.today));
    this.formContainer.get('reservationForm')['controls'].startHour.setValue({ hour: moment().hour(), minute: 0 });
    this.formContainer.get('reservationForm').valueChanges.subscribe(res => {
      if (res.startDate) {
        this.setEndDate();
      }
    })
  }

  public handleAddressChange(address: Address) {

    var city = "";
    this.adresseForm.patchValue({
      lat: address.geometry.location.lat().toString(),
      lng: address.geometry.location.lng().toString(),
      adresse: address.formatted_address
    });

    city = address.address_components.find((element) => element.types.includes("locality")).long_name || "";

    // affect adresses to my variables
    this.adresseForm.patchValue({
      city: (city != undefined) ? city : ""
    });
  }

  onSubmit() {

    this.submitted = true;
    if (this.studioList.length == 0) {
      Swal.fire("Attention", "Impossible de valider une résrvation sans avoir de studio", "info");
      return;
    }
    let userForm = this.formContainer.get('userForm');
    let reservationForm = this.formContainer.get('reservationForm');

    Swal.fire({
      title: "Étes-vous sûre de vouloir valider cette réservation ?",
      cancelButtonText: 'NON',
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: "OUI",
      icon: "info"
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.user) {

          if (this.user.profile != null || this.user.profile != undefined) {
            this.reservationService.create(this.get_reservation(reservationForm, this.user.id)).subscribe((reservation: any) => {
              this.studioService.contactStudio({ "array": this.studioList.map(studio => ({ "contact": studio.id, "send_msg": false, "reservation": reservation.id })) }).subscribe(studio => {
                this.paymentService.create({ "payment_id": "", "charge_id": "", "reservation": reservation.id, "facture": "", "type": "hotline" }).subscribe(payment => {
                  Swal.fire("Bien", "Votre réservation a bien été créée", "success");
                  this.router.navigate(['/reservations']);
                }, error => {
                  Swal.fire("Attention", "Une érreur est survenue", "error");
                  return;
                })
              }, error => {
                Swal.fire("Attention", "Une érreur est survenue", "error");
                return;
              })
            }, error => {
              Swal.fire("Attention", "Une érreur est survenue", "error");
              return;
            });
          } else {
            this.profileService.create_profile(this.user.id, userForm.value.email, userForm.value.complete_name, userForm.value.phone, "", "", "", "", "false", "false", "false").subscribe(profile => {
              this.reservationService.create(this.get_reservation(reservationForm, this.user.id)).subscribe((reservation: any) => {
                this.studioService.contactStudio({ "array": this.studioList.map(studio => ({ "contact": studio.id, "send_msg": false, "reservation": reservation.id })) }).subscribe(studio => {
                  this.paymentService.create({ "payment_id": "", "charge_id": "", "reservation": reservation.id, "facture": "", "type": "hotline" }).subscribe(payment => {
                    Swal.fire("Bien", "Votre réservation a bien été créée", "success");
                    this.router.navigate(['/reservations']);
                  }, error => {
                    Swal.fire("Attention", "Une érreur est survenue", "error");
                    return;
                  })
                }, error => {
                  Swal.fire("Attention", "Une érreur est survenue", "error");
                  return;
                })
              }, error => {
                Swal.fire("Attention", "Une érreur est survenue", "error");
                return;
              });
            });
          }



        } else {
          this.authService.register_with_phone(userForm.value.phone, userForm.value.email, '', 'phone').subscribe(res => {
            this.profileService.create_profile(res.user.id, userForm.value.email, userForm.value.complete_name, userForm.value.phone, "", "", "", "", "false", "false", "false").subscribe(profile => {
              this.stripeService.create_customer(res.user.id, userForm.value.email, userForm.value.complete_name).subscribe(token => {

                this.reservationService.create(this.get_reservation(reservationForm, res.user.id)).subscribe((reservation: any) => {
                  this.studioService.contactStudio({ "array": this.studioList.map(studio => ({ "contact": studio.id, "send_msg": false, "reservation": reservation.id })) }).subscribe(studio => {
                    this.paymentService.create({ "payment_id": "", "charge_id": "", "reservation": reservation.id, "facture": "", "type": "hotline" }).subscribe(payment => {
                      Swal.fire("Bien", "Votre réservation a bien été créée", "success");
                      this.router.navigate(['/reservations']);
                    }, error => {
                      Swal.fire("Attention", "Une érreur est survenue", "error");
                      return;
                    })
                  }, error => {
                    Swal.fire("Attention", "Une érreur est survenue", "error");
                    return;
                  })
                }, error => {
                  Swal.fire("Attention", "Une érreur est survenue", "error");
                  return;
                })
              })
            })
          }, error => {
            Swal.fire("Attention", "Veuillez fournir une adresse email valide", "error");
            this.step = "userForm";
            setTimeout(() => this.formContainer.get('userForm')['controls']['email'].setErrors({ 'incorrect': true }));
          })
        }
      }
    })

  }

  get_reservation(reservationForm: any, user: string) {
    return {
      "start_date": this.objectToMoment('startDate', 'startHour'),
      "end_date": this.objectToMoment('endDate', 'endHour', true),
      "type_selected": this.prestationTypes.filter(i => i.name == reservationForm.value['prestation_type'])[0].value,
      "etat": false,
      "is_deleted": false,
      "name": "hotline",
      "user": user
    };
  }


  async goToStep(form: string) {
    if (this.step == 'reservationForm') {
      this.setEndDate();
    }

    this.submitted = true;

    if (this.formContainer.get(this.step).invalid) {
      Swal.fire("Attention", "Vérifier les champs du formulaire", "warning");
      return;
    }
    if (this.step == 'userForm') {
      this.user = null;
      let userForm = this.formContainer.get('userForm');
      const myPromise: Promise<any> = new Promise((resolve, reject) => {
        this.profileService.getByEmail({ email: userForm.value.email, phone: userForm.value.phone, check: true }).then((available: any) => {
          {

            if (available.foundUser || available.foundLoginPhone) {
              let text = (available.foundUser) ? "d'adresse email" : "de numéro téléphone";
              Swal.fire({
                title: "Cet utilisateur existe déjà voulez vous continuer ou changer " + text + " ?",
                cancelButtonText: 'changer',
                cancelButtonColor: 'red',
                showCancelButton: true,
                confirmButtonText: "continuer",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  if (available.foundUser) {
                    this.user = available.foundUserData;
                    this.initialyzeAfterNextStep(form);
                  } else if (available.foundLoginPhone) {
                    this.user = available.foundLoginPhoneData;
                    this.initialyzeAfterNextStep(form);
                  }
                }
              })
            } else if (available.foundPhone) {
              Swal.fire("Attention", "Ce numéro de téléphone est déjà lié à un profil, veuillez choisir un autre", "info");
            } else {
              this.initialyzeAfterNextStep(form);
            }
          }
        }, err => {
          console.log(err);
        })
      })

      return myPromise;
    }


    if (form == 'studiosForm') {
      if (moment(this.startDateTime.format("DD-MM-YYYY HH:mm:ss")).diff(moment((moment().format("DD-MM-YYYY HH:mm:ss")))) <= 0) {
        Swal.fire("Attention", "Il est impossible de choisir une date passé", "warning");
        return
      }
      this.loading = false;
    }
    this.initialyzeAfterNextStep(form);
  }

  initialyzeAfterNextStep(form: any) {
    this.submitted = false;
    this.step = form;
  }


  objectToMoment(date: any, hour: any, is_endDate?: boolean) {
    let reservationForm = this.formContainer.get('reservationForm');
    if (is_endDate) {
      return moment(reservationForm['controls'][date].value.year + "-" + reservationForm['controls'][date].value.month + "-" + reservationForm['controls'][date].value.day + " " + reservationForm['controls'][hour].value.hour + ":" + reservationForm['controls'][hour].value.minute).add("hour", -1).format("YYYY-MM-DD HH:mm");
    } else {
      return moment(reservationForm['controls'][date].value.year + "-" + reservationForm['controls'][date].value.month + "-" + reservationForm['controls'][date].value.day + " " + reservationForm['controls'][hour].value.hour + ":" + reservationForm['controls'][hour].value.minute).format("YYYY-MM-DD HH:mm");
    }
  }

  setEndDate() {
    if (!this.changingStartDate) {
      this.changingStartDate = true;
      let reservationForm = this.formContainer.get('reservationForm');
      const startDate = reservationForm.value['startDate'];
      const startHour = reservationForm.value['startHour'];

      this.startDateTime = moment.utc({
        year: startDate.year,
        month: startDate.month - 1,
        date: startDate.day,
        hour: startHour.hour,
        minute: startHour.minute
      });

      const endDateTime = moment.utc(this.startDateTime).add(reservationForm.value['nbr_hours'], 'hours');

      this.ngZone.run(() => {
        reservationForm.patchValue({
          endDate: {
            year: endDateTime.year(),
            month: endDateTime.month() + 1,
            day: endDateTime.date(),
          },
          endHour: {
            hour: endDateTime.hours(),
            minute: endDateTime.minutes(),
            second: 0,
          }
        });

      });
      this.changingStartDate = false;
    }
  }

  async suggestedStudios(lat?: string, lng?: string, city?: string, min_distance?: number, max_distance?: number, min_price?: number, max_price?: number, webFlowOptions?: any) {
    this.loading = true;
    this.studioList = [];
    let webFlowParams = Object.keys(webFlowOptions)
      .filter((key) => webFlowOptions[key] === true)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(webFlowOptions[key])}`)
      .join('&');
    try {
      let type = this.prestationTypes.filter(i => i.name == this.formContainer.get('reservationForm').value['prestation_type'])[0].value;
      const studios: any = await this.studioService.get_studios_arround_for_reservation(type, this.objectToMoment('startDate', 'startHour'), this.objectToMoment('endDate', 'endHour'), lat, lng,  '' /*city*/, min_distance, max_distance, min_price, max_price, webFlowParams);
      studios.forEach(x => {
        if (this.studioList.length < 10) {
          this.studioList.push(x);
        } else {
          this.studioSuggestionList.push(x);
        }
      });
      this.loading = false;

    } catch (err) {
      console.error(err);
    }
  }

  back() {
    this.location.back();
  }

  selectEvent(item) {
    this.studioList.push({ ...item, 'deleteable': true })
    this.removeItem(this.studioSuggestionList, item);
    console.log(this.studioSuggestionList);

  }



  removeItem(list: any, item: any) {
    const index = list.indexOf(item, 0);
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  removeStudio(item: any) {
    this.removeItem(this.studioList, item);
    this.studioSuggestionList.push(item);
  }

  search() {
    if (this.adresseForm.invalid) {
      Swal.fire("Atention", "Il faut séléctionner une adresse", 'error');
      return;
    }
    this.searching = true;
    this.suggestedStudios(this.adresseForm.value.lat, this.adresseForm.value.lng, this.adresseForm.value.city, this.distanceSlider.minValue, this.distanceSlider.maxValue, this.priceSlider.minValue, this.priceSlider.maxValue, this.webFlowOptionForm.value);
  }

}
