import { Component, OnInit } from '@angular/core';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'design_app', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'education_atom', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'location_map-big', class: '' },
    // { path: '/notifications', title: 'Notifications',  icon:'ui-1_bell-53', class: '' },

    // { path: '/user-profile', title: 'Profile',  icon:'users_single-02', class: '' },
    { path: '/studios', title: 'Studios',  icon:'design_bullet-list-67', class: '' },
    { path: '/utilisateurs', title: 'Utilisateurs',  icon:'design_bullet-list-67', class: '' },
    { path: '/reservations', title: 'Réservations',  icon:'design_bullet-list-67', class: '' },
    { path: '/pass-culture', title: 'Pass Culture',  icon:'design_bullet-list-67', class: '' },
    // { path: '/abonnements', title: 'Abonnements',  icon:'business_money-coins', class: '' },
    { path: '/contactez-nous', title: 'Messages',  icon:'business_money-coins', class: '' },
    { path: '/tags', title: 'Tags',  icon:'shopping_tag-content', class: '' },
    { path: '/settings', title: 'Settings',  icon:'ui-1_settings-gear-63', class: '' },
    // { path: '/coupon', title: 'Code promo',  icon:'business_money-coins', class: 'active active-pro' }

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };
}
