import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  get(): Observable<any> {
    return this.http.get(environment.api + 'tags')
  }

  getTag(id: string): Observable<any> {
    return this.http.get(environment.api + 'tags/' + id)
  }

  update(id:string,data: any, file): Observable<any> {
    const formData = new FormData();
    if (file) {
      formData.append("files.image", file);
    }
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + 'tags/'+id, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    })
  }

  delete(id: any): Observable<any> {
    return this.http.delete(environment.api + 'tags/' + id, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    })
  }

  create(data: any, file: any): Observable<any> {
    const formData = new FormData();
    formData.append("files.image", file);
    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + 'tags', formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    })
  }
}
