import { Component, OnInit } from '@angular/core';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TagsService } from './../../../services/tags/tags.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.css']
})
export class TagsListComponent implements OnInit {
  list = [];
  loading = true;
  constructor(private tagsApi: TagsService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.initialyze();
  }

  initialyze(){
    this.tagsApi.get().subscribe(res => {
      this.spinner.show();
      this.list = res.map(items => {
        return items;
      })
    }).add(res => {
      this.loading = false;
      this.spinner.hide();
    })
  }

  delete(id: string) {
    Swal.fire({
      text: 'Êtes vous sur de vouloir supprimer ce meta tag?',
      icon:'info',
      showCancelButton:true,
      cancelButtonText:'Annuler',
      cancelButtonColor:"red"
    }).then(result=>{
      if(result.isConfirmed){
        this.tagsApi.delete(id).subscribe(res=>{
          Swal.fire('Supprimé','Tag supprimé avec succès','success');
          this.initialyze();
        },err=>{
          Swal.fire('Érreur','Une érreur est survenue','error');
        })
      }
    })
  }


}
