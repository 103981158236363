import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./modules/components/components.module";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./modules/admin-layout/admin-layout.component";
import { LoginComponent } from "./modules/login/login/login.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { EditComponent } from "./modules/studios/edit/edit.component";
import { EditUserComponent } from "./modules/utilisateur/edit/edit.component";
import { AddAbonnementComponent } from "./modules/abonnements/add-abonnement/add-abonnement.component";
import { AddCodePromoComponent } from "./modules/code-promo/add-abonnement/add-code-promo.component";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { CreateStudioComponent } from "./modules/studios/create-studio/create-studio.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CreateComponent } from "./modules/utilisateur/create/create.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { ReservationsComponent } from "./modules/reservations/reservations.component";
import { DetailsComponent } from "./modules/reservations/details/details.component";
import { TagsComponent } from "./modules/tags/tags.component";
import { SettingsComponent } from "./modules/settings/settings.component";
import { TagsListComponent } from "./modules/tags/tags-list/tags-list.component";
import { DataTablesModule } from "angular-datatables";
import { SortablejsModule } from "ngx-sortablejs";
import { HotlineConfirmationComponent } from './modules/reservations/create-reservation/create-reservation.component';
import { NgxSliderModule } from "@angular-slider/ngx-slider";

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';


import { environment } from '../environments/environment';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    NgxSpinnerModule,
    DataTablesModule,
    AutocompleteLibModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    GooglePlaceModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgxSliderModule,
    RecaptchaV3Module

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    EditComponent,
    EditUserComponent,
    AddAbonnementComponent,
    AddCodePromoComponent,
    CreateStudioComponent,
    CreateComponent,
    ReservationsComponent,
    DetailsComponent,
    TagsComponent,
    SettingsComponent,
    TagsListComponent,
    HotlineConfirmationComponent
  ],

  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
