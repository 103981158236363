import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  endPoint = "settings/"
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  get(): Observable<any> {
    return this.http.get(environment.api + 'settings')
  }

  update(data: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint + 'update', { "array": data }, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    })
  }

  globalCount(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "global/count", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

}
