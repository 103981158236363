import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '../../../services/profile/profile.service';
import { UtilisateurService } from '../../../services/utilisateur/utilisateur.service';
import { environment } from '../../../../environments/environment';
import { FormService } from '../../../services/form/form.service';
import Swal from 'sweetalert2';
import { LinksService } from '../../../services/links/links.service';
import moment from 'moment';



@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditUserComponent implements OnInit {
  profile: any;
  Form: UntypedFormGroup;
  socialMediaForm: UntypedFormGroup;
  loading = true;
  avatar = environment.defaultAvatar;
  linksToDelete = [];
  submitted = false;
  user: any;
  userDeleted = false;

  base_api: string = environment.base_api;

  social = [
    { name: "Facebook", link: "https://www.Facebook.com" }
    , { name: "Twitter", link: "https://www.Twitter.com" }
    , { name: "Instagram", link: "https://www.Instagram.com" }
    , { name: "Tiktok", link: "https://www.Tiktok.com" }
    , { name: "Youtube", link: "https://www.Youtube.com" }
  ];
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private userService: UtilisateurService,
    private formService: FormService,
    private linksService: LinksService,
    private profileService: ProfileService,
    private location: Location,
    private fb: UntypedFormBuilder) {
    this.Form = this.fb.group({
      full_name: [''],
      bio: [''],
      all_city: [false],
      isSponsored: [false],
      is_draft: [false],
    })
    this.socialMediaForm = this.fb.group({
      array: this.fb.array([])
    })
  }
  ngOnInit(): void {
    this.route.params.subscribe(res => {
      this.profileService.get_profile_by_user_id(res.id).subscribe(res => {

        this.spinner.show();
        res.map(profile => {

          this.profile = profile;

          this.userService.get_by_id(profile.user.id).subscribe(user => {
            this.user = user;
            if (this.deleted(user.email)) {
              this.userDeleted = true;
              this.Form.disable();
            }
          })
          this.Form.patchValue(profile);
          this.initialyzeLink(this.socialMediaForm, profile.links);
        })
      }).add(() => {
        this.loading = false;
        this.spinner.hide();
      })
    })
  }

  get f() {
    return this.Form.controls;
  }

  connectWith() {

    window.open(
      //environment.webUrl + "connect-with?user=" + this.profile.user.id + "&jwt=" + localStorage.getItem("auth-token-bo"),
      environment.webFlowLink+"/app?action=connect-with&user=" + this.profile.user.id + "&jwt=" + localStorage.getItem("auth-token-bo"),
      "_blank"
    );


  }

  onSubmit() {
    this.submitted = true;
    if (this.Form.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }
    if (this.socialMediaForm.invalid) {
      Swal.fire('Erreur', 'Veuillez renseigner des liens réseaux sociaux valides', 'error');
      return;
    }
    if (this.profile.user.type != 'freelance') {
      this.formService.emptyControl(this.Form, 'bio');
    }
    this.spinner.show();
    let profile = this.Form.value;
    let data = {
      full_name: profile.full_name,
      bio: profile.bio,
      //phone: profile.phone,
      all_city: profile.all_city,
      isSponsored: profile.isSponsored,
      is_draft: profile.is_draft
    }
    this.profileService.update_profile(this.profile.id, data, null).subscribe((res: any) => {
      // create links 
      this.linksService.create(this.socialMediaForm.value).subscribe(type => {
        // delete types
        this.linksService.delete({ ids: this.linksToDelete }).subscribe(deleteType => { }, err => {
          this.spinner.hide();
        })
      }, err => {
        console.log("error type");
        this.spinner.hide();
      })
      Swal.fire('Félicitation', "Profile modifié avec succès", "success");
      this.spinner.hide();

    }, err => {
      Swal.fire('Erreur', 'Une erreur est survenue', "error");
      this.spinner.hide();
    })
  }


  back() {
    this.location.back();
  }

  hasSocial(value: string) {
    return this.profile.links.some(x => x.name == value);
  }

  getSocialUrl(value: string) {
    if (this.profile.links.filter(x => x.name == value).length > 0) {
      return this.profile.links.filter(x => x.name == value)[0].link;
    } else {
      return null
    }
  }
  // social media
  createLinks(name: string, link: string) {
    return this.fb.group(
      {
        name: name,
        checked: true,
        link: [link, [Validators.required, this.formService.link_validator]],
        profile: this.profile.id
      })
  }

  addLink(form: UntypedFormGroup, name: string, link: string) {
    const control = <UntypedFormArray>form.get('array');
    control.push(this.createLinks(name, link));
  }

  selectLink(form: UntypedFormGroup, event: any) {
    if (event.target.checked) {
      this.addLink(form, event.target.value, null);
    }
    else {
      this.deleteArray(form, this.arrayIndex(form, event.target.value));
    }
  }
  deleteArray(form: UntypedFormGroup, i: number) {
    const control = <UntypedFormArray>form.get('array');
    control.removeAt(i);
  }

  arrayIndex(form: UntypedFormGroup, name: string) {
    let array: Array<any> = form.value['array'];
    return array.findIndex(x => x.name == name);
  }

  initialyzeLink(form: UntypedFormGroup, links: any) {
    links.forEach(items => {
      this.linksToDelete.push(items.id);
      this.addLink(form, items.name, items.link);
    })
  }

  showDateHour(date: any) {
    return moment(date).utcOffset(0).format('DD-MM-YYYY HH:mm');
  }

  deleted(value: string) {
    return value.includes('deleted');
  }

}
