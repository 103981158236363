import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})

export class UtilisateurService {

  endPoint = "users";
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create(name: string, adresse: string, city: string, country: string, lat: string, lng: string, postal_code: string, route: string, phone: string, email: string, siren: string, description: string, prestations: any, tarif: string, web_site: string, facebook: string, instagram: string, type: string, medias: Array<File>): Observable<any> {
    let data = {
      name: name,
      adresse: adresse,
      city: city,
      country: country,
      lat: lat,
      lng: lng,
      postal_code: postal_code,
      route: route,
      phone: phone,
      email: email,
      siren: siren,
      description: description,
      prestations: prestations,
      tarif: tarif,
      web_site: web_site,
      facebook: facebook,
      instagram: instagram,
      type: type,
    }
    const formData = new FormData();
    medias.forEach(element => {
      formData.append("files.medias", element);
    });

    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + this.endPoint, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  update(id: string, name: string, adresse: string, city: string, country: string, lat: string, lng: string, postal_code: string, route: string, phone: string, email: string, siren: string, description: string, web_site: string, medias: Array<File>): Observable<any> {
    var user = this.tokenStorageService.getUser().id;
    let data = {
      user: user,
      name: name,
      adresse: adresse,
      city: city,
      country: country,
      lat: lat,
      lng: lng,
      postal_code: postal_code,
      route: route,
      phone: phone,
      email: email,
      siren: siren,
      description: description,
      web_site: web_site
    }
    const formData = new FormData();
    medias.forEach(element => {
      formData.append("files.medias", element);
    });

    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + this.endPoint + '/' + id, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  get_by_id(id: string): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/" + id, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  get_by_email(email: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "?email=" + email, {
       headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
     }).toPromise()
     .then(
       res => {
         resolve(res[0]);

       },
       msg => { // Error
         reject(msg);
       }
     );
    })
    return promise;
  }

  get_alls(filters: String): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/?_limit=-1&_sort=createdAt:DESC" + filters, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  /*
  get_last(number: number) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "/?_limit=" + number + "&_sort=createdAt:DESC&id_ne=6186a7611c2e1301bbd6d0db", {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
    return promise
  }
  */
  get_last(number: number): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/?_limit=" + number + "&_sort=createdAt:DESC&id_ne=6186a7611c2e1301bbd6d0db", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  count(type: string): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/count?type=" + type, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  search(value: string): Observable<any> {
    let url = environment.api + this.endPoint + "/?_q=" + value + "&id_ne=6186a7611c2e1301bbd6d0db";
    return this.http.get(url, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  block_user(id: string, status: boolean): Observable<any> {
    return this.http.put(environment.api + this.endPoint + "/" + id, { "blocked": status },
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }

  delete(userId: string): Observable<any> {
    return this.http.post(environment.api + "profiles/deleteUser/" + userId, {}, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  getMe(jwt: String): Observable<any> {
    return this.http.get(environment.api + 'users/me', { headers: { Authorization: 'Bearer ' + jwt, "content-type": "application/json" } },);
  }
}
