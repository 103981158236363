import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { SettingsService } from './../../services/settings/settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  Form_top_header: UntypedFormGroup;
  Form_bottom_header: UntypedFormGroup;
  submitted = false;
  constructor(private settingsApi:SettingsService,private location: Location, private spinner: NgxSpinnerService, private fb: UntypedFormBuilder) {
    this.Form_top_header = this.fb.group({
      id: [''],
      title: [''],
      value: ['']
    })
    this.Form_bottom_header = this.fb.group({
      id: [''],
      title: [''],
      value: ['']
    })
  }

  get f() { return this.Form_top_header.controls; }
  get f2() { return this.Form_bottom_header.controls; }

  ngOnInit() {
    this.settingsApi.get().subscribe(data=>{
      this.Form_top_header.patchValue(data[0]);
      this.Form_bottom_header.patchValue(data[1]);
      console.log(this.Form_bottom_header.value,this.Form_top_header.value);
    })
  }

  back() {
    this.location.back();
  }
  onSubmit() {
    let body = [this.Form_top_header.value, this.Form_bottom_header.value];
    this.spinner.show();
    this.settingsApi.update(body).subscribe(response => {
      Swal.fire('Félicitation',"Scripts ajouté avec succès","success");
    },err=>{
      Swal.fire('Érreur',"Une érreur est survenu","error");
    }).add(err => {
      this.spinner.hide();
    })
  }

}
