
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from './../../../services/profile/profile.service';
import { environment } from './../../../../environments/environment.prod';
import { StudioService } from './../../../services/studio/studio.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import Swal from 'sweetalert2';
import moment from 'moment';
import { FormService } from './../../../services/form/form.service';
import { HorairesService } from './../../../services/horaires/horaires.service';
import { LinksService } from './../../../services/links/links.service';
import { OuvertureService } from './../../../services/ouverture/ouverture.service';
import { PrestationsService } from './../../../services/prestations/prestations.service';
import { MediasService } from './../../../services/medias/medias.service';
import { TypeService } from './../../../services/type/type.service';
import { Location } from '@angular/common';
import { UtilisateurService } from './../../../services/utilisateur/utilisateur.service';
import { Studio } from './../../../classes/studio.model';



@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  studio: any;
  Form: UntypedFormGroup;
  prestationForm: UntypedFormGroup;
  horaireForm: UntypedFormGroup;
  socialMediaForm: UntypedFormGroup;
  avatar = environment.defaultAvatar;
  profile: any;
  loading = true;
  submitted = false;
  // images
  files: Array<File> = [];
  imagesToDelete = [];
  images = new Array;
  typeStudio: any;
  data: any[][] = [];
  users: any;
  selectedUser: any;

  base_api: string = environment.base_api;

  // delete
  //typesToDelete = [];
  linksToDelete = [];
  days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
  social = [
    { name: "Facebook", link: "https://www.Facebook.com" }
    , { name: "Twitter", link: "https://www.Twitter.com" }
    , { name: "Instagram", link: "https://www.Instagram.com" }
    , { name: "Tiktok", link: "https://www.Tiktok.com" }
    , { name: "Youtube", link: "https://www.Youtube.com" }
  ];


  initTypes: Array<any> = [];

  generate_chatgpt_description: boolean = false;
  old_description: string = "";

  //google place (paris france by default)
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild("itemTemplate") itemTemplate: any;
  keyword = 'email';


  selectEvent(item) {
    // do something with selected item
  }
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }
  public handleAddressChange(address: Address) {
    var addressComponents = address;
    this.getAdress(addressComponents);
  }
  constructor(
    private mediaService: MediasService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private studioService: StudioService,
    private ouvertureService: OuvertureService,
    private horaireService: HorairesService,
    private linksService: LinksService,
    private typeService: TypeService,
    private prestationService: PrestationsService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private location: Location,
    private userService: UtilisateurService,
  ) {
    this.Form = this.fb.group({
      name: ['', Validators.required],
      adresse: ['', Validators.required],
      city: [''],
      country: [''],
      lat: ['', Validators.required],
      lng: ['', Validators.required],
      postal_code: [''],
      route: [''],
      phone: ['', Validators.required],
      email: [''],
      siren: [''],
      description: [''],
      ical: [''],
      medias: [''],
      web_site: [''],
      isSponsored: [false],
      is_draft: [false],
      commission: 0,
      minhours_day: 0,
      has_smok: [false],
      has_internet: [false],
      has_cabin: [false],
      has_rest_room: [false],
      has_kitchen: [false],
      is_enregistrement: [false],
      is_mix: [false],
      is_mastering: [false],
      is_beatmaking: [false],
      has_bathroom: [false]
      //minhours_semiday: 0
    })
    this.prestationForm = this.fb.group({
      array: this.fb.array([], [Validators.required])
    })
    this.horaireForm = this.fb.group({
      array: this.fb.array([])
    })

    this.socialMediaForm = this.fb.group({
      array: this.fb.array([])
    })
  }

  get f() { return this.Form.controls; }

  ngOnInit(): void {
    this.spinner.show();
    this.getInitData();
  }


  getInitData() {
    this.spinner.show();    
    this.fillPrestation();
    this.route.params.subscribe(res => {
      this.studioService.get_by_id(res.id).subscribe(studio => {
        this.spinner.show();
        this.studio = studio;
        this.initTypes = studio.types;
        if(!this.studio.user)
          this.loadUsers();

        this.fillForm(studio);
        this.Form.patchValue(studio);

        console.log("======== init types ==========");
        console.log(this.initTypes);
        console.log(this.prestationForm.value.array);

      }).add(() => {
        this.loading = false;
        this.spinner.hide();
      })
    });
  }

  onSubmit() {
    let errorList: any;
    let requiredType: Array<any>;
    let errorsText = "";
    this.submitted = true;
    if (this.horaireForm.invalid || this.prestationForm.invalid || (this.files.length < 1 && this.Form.value.medias.length == 0) || this.Form.invalid || this.titleHasSymbol(this.Form.value.name)) {

      this.prestationForm.controls.array['controls'].map(x => {
        if (x.errors && x.errors.message) {
          errorsText += x.errors.message + ' pour le type <strong>' + x.value.name + "</strong><br>";
          return
        }
      })

      errorList = this.catchFormErrors(this.prestationForm);
      requiredType = this.catchFormChildrenErrors(this.prestationForm)['types'];
      if (this.files.length < 1 && this.Form.value.medias.length == 0) {
        errorsText += 'il faut uploader une image au moins<br>';
      }
      if (this.Form.invalid) {
        errorsText += '- Veuillez vérifier vos informations<br>'
      }

      if (errorList.includes('array')) {
        errorsText += '- Veuillez sélectionner au moins un type !!<br>';
      } else {
        errorList = this.catchFormChildrenErrors(this.prestationForm)['errors'];
      }

      if (requiredType.length > 0) {
        errorsText += '- Veuillez sélectionner au moins une prestation pour ' + requiredType.toString() + '<br>';
      }

      if (this.horaireForm.invalid) {
        errorsText += '- Veuillez vérifier les horaires<br>';
      }

      if (errorList.includes('tarif')) {
        errorsText += '- Veuillez remplir les champs vides<br>';
      }


      if (this.socialMediaForm.controls.array.value.length > 0) {
        let found = this.socialMediaForm.controls.array.value.filter((item: any) => { return item.link == null || item.link == "" });
        if (found.length > 0) {
          errorsText += '- Veuillez renseigner des liens réseaux sociaux valides<br>';
        }
      }

      if (this.titleHasSymbol(this.Form.value.name)) {
        errorsText += '- Le nom du studio ne doit pas contenir de symbols<br>';
      }

      Swal.fire('ATTENTION', errorsText, 'error');
      return
    }


    this.spinner.show();
    let studio = new Studio(this.Form.value);
    delete studio['medias'];
    studio['medias'] = this.Form.value.medias;
    this.imagesToDelete.forEach(element => {
      this.mediaService.delete_pictures(element).subscribe(
        res => {
          this.imagesToDelete = []; console.log(res);
        },
        err => { console.log(err) }
      )
    });

    studio.id = this.studio.id;
    this.studioService.update(studio, this.files).subscribe(studio => {
      this.horaireForm.value.array.map(x => x.studio = studio.id);
      this.prestationForm.value.array.map(x => x.studio = studio.id);
      this.socialMediaForm.value.array.map(x => x.studio = studio.id);

      // create ouverture
      let listHoraires = this.horaireForm.value.array.map(obj => {
        let hours = obj.horaires;
        obj.horaires = [];
        return hours;
      });
      this.ouvertureService.update(this.horaireForm.value).subscribe(ouverture => {
        let listHoraireToAdd = [];

        if (ouverture) {
          for (let i = 0; i < ouverture.length; i++) {
            if (!this.horaireForm.value.array[i].full_day) {
              listHoraires[i].forEach(element => {
                element.ouverture = ouverture[i].id;
                listHoraireToAdd.push(element);
              });
            }
          }
        }

        // create links 
        this.linksService.create(this.socialMediaForm.value).subscribe(type => {
          // delete types
          this.linksService.delete({ ids: this.linksToDelete }).subscribe(deleteType => { }, err => {
            console.log("delete type error");
            this.spinner.hide();
          })
        }, err => {
          Swal.fire("Erreur", "Une érreur est survenue (type)", "error");
          this.spinner.hide();
        })
      });


      let typesToAdd = this.prestationForm.value.array.filter((item: any) => { return item.id == null; });
      let typesToEdit = this.prestationForm.value.array.filter((item: any) => { return item.id != null; });
      let typesToDelete = this.initTypes.filter(item1 => !typesToEdit.some(item2 => item2.id === item1.id));


      // create types 
      this.typeService.create({ "array": typesToAdd }).subscribe(type => {
        // multi update types
        this.typeService.multiUpdate({ "array": typesToEdit }).subscribe(type => {
          this.typeService.delete({ ids: typesToDelete }).subscribe(deleteType => {
            Swal.fire("Félicitation", "Studio modifié avec succès", "success");
            this.router.navigate(['/studios']);
            this.spinner.hide();
          }, err => {
            Swal.fire("Erreur", "Une érreur est survenue (type)", "error");
            this.spinner.hide();
          });
        }, err => {
          Swal.fire("Erreur", "Une érreur est survenue (type)", "error");
          this.spinner.hide();
        });

      }, err => {
        Swal.fire("Erreur", "Une érreur est survenue (type)", "error");
        this.spinner.hide();
      });






    }, err => {
      console.log(err);
      this.spinner.hide();
      let errMsgs = err?.error?.data?.errors;
      let message = `${err.error?.message}: ${errMsgs?.map(m => m?.message || m)}`;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une érreur est survenue",
        footer: `<p class="alert alert-danger">${message}</p>`
      });
    })
  }

  fillForm(studio: any) {
    this.old_description = studio.description;
    this.Form.patchValue(studio);
    this.intialyzePrestations(this.prestationForm, studio.types);
    this.initialyzeLink(this.socialMediaForm, studio.links);
  }

  intialyzePrestations(form: UntypedFormGroup, types: any) {
    let currentType = [];
    types.forEach(element => {
      //this.typesToDelete.push(element.id);

      this.prestationService.get_prestation_by_type(element.name).subscribe(prestation => {
        this.data[element.name] = prestation.map(items => {
          return items;
        })
      })
      if (!currentType.includes(element.name)) {
        this.addArray(form, element.id, element.name, element.tarif, element.tarif_promo);
      }
      currentType.push(element.name);
      element.prestations.forEach(prestation => {
        this.addPrestation(form, this.arrayIndex(form, element.name), prestation);
      });
    });
  }

  initialyzeHoraires(form: UntypedFormGroup, ouvertures: any) {
    const control = <UntypedFormArray>form.get('array');
    ouvertures.forEach(day => {
      control.push(this.createDay(day.day, day.open, day.full_day, day.id));
    });
  }

  initialyzeLink(form: UntypedFormGroup, links: any) {
    links.forEach(items => {
      this.linksToDelete.push(items.id);
      this.addLink(form, items.name, items.link);
    })
  }
  catchFormErrors(form: UntypedFormGroup) {
    let errors = [];
    for (let el in form.controls) {
      if (form.controls[el].errors) {
        errors.push(el);
      }
    }
    return errors;
  }

  catchFormChildrenErrors(form: UntypedFormGroup) {
    let errors = []
    let requiredType = [];
    for (let i = 0; i < form.controls.array['controls'].length; i++) {
      let controlList = form.controls.array['controls'][i];
      for (let el in controlList.controls) {
        if (controlList.controls[el].errors) {
          if (!requiredType.includes(controlList.value.name) && el != "tarif") {
            requiredType.push(controlList.value.name)
          }
          if (!errors.includes(el)) {
            errors.push(el);
          }
        }
      }
    }
    return { errors: errors, types: requiredType };
  }

  getAdress(addressComponents) {
    var city, route, number, country, postal, shourt, departement = "";

    this.Form.patchValue({
      lat: addressComponents.geometry.location.lat().toString(),
      lng: addressComponents.geometry.location.lng().toString(),
      adresse: addressComponents.formatted_address
    });
    addressComponents.address_components.filter(function (component) {
      switch (component.types[0]) {
        case "route": // route
          route = component.long_name;
          return true;
        case "locality": // city
          city = component.long_name;
          return true;
        case "street_number": // street number
          number = component.long_name;
          return true;
        case "country": // country (logn and short name)
          country = component.long_name;
          shourt = component.short_name;
          return true;
        case "administrative_area_level_2": // country (logn and short name)
          departement = component.long_name;
          return true;
        case "postal_code": // postal code
          postal = component.long_name;
          return true;
        default:
          return false;
      }
    });
    // affect adresses to my variables
    this.Form.patchValue({
      country: (country != undefined) ? country : "",
      city: (city != undefined) ? city : "",
      postalCode: (postal != undefined) ? postal : "",
      route: (route != undefined) ? route : ""
    });
  }

  uploadFile(event) {
    var reader = new FileReader();
    this.files.push(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.images.push(reader.result);
    }
  }

  removeImage(index: number) {
    Swal.fire({
      text: 'Voulez-vous supprimer cette photo',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: `Supprimer`,
      cancelButtonText: `Annuler`,
      cancelButtonColor: `#FF0000`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.files.splice(index, 1);
        this.images.splice(index, 1);
        Swal.fire('Supression', 'Effectuée avec succès', 'success')
      }
    })
  }

  hasSocial(value: string) {
    return this.studio.links.some(x => x.name == value);
  }

  getSocialUrl(value: string) {
    if (this.studio.links.filter(x => x.name == value).length > 0) {
      return this.studio.links.filter(x => x.name == value)[0].link;
    } else {
      return null
    }
  }
  fillPrestation() {
    this.prestationService.get_prestations().subscribe(async res => {
      const arr = res
      const ids = arr.map(o => o.type_prestation)
      this.typeStudio = arr.filter(({ type_prestation }, index) => !ids.includes(type_prestation, index + 1));
    })
  }
  // (prestations & type prestations)
  createArray(id: string, name: string, tarif: string, tarif_promo: string) {
    return this.fb.group({
      id: id,
      name: name,
      prestations: new UntypedFormArray([], [Validators.required]),
      tarif: [tarif, Validators.required],
      tarif_promo: [tarif_promo],
      studio: this.studio.id
    }, { validators: this.checkTarif('tarif', 'tarif_promo') });
  }

  getArray(form) {
    return form.controls.array.controls;
  }

  getPrestation(form) {
    return form.controls.prestations.controls;
  }

  addArray(form: UntypedFormGroup, id: string, name: string, tarif: string, tarif_promo: string) {
    const control = <UntypedFormArray>form.get('array');
    control.push(this.createArray(id, name, tarif, tarif_promo));
  }

  deleteArray(form: UntypedFormGroup, i: number) {
    const control = <UntypedFormArray>form.get('array');
    control.removeAt(i);
  }

  addPrestation(form: UntypedFormGroup, i: number, value: string) {
    const control = <UntypedFormArray>form.get('array')['controls'][i].get('prestations');
    control.push(new UntypedFormControl(value));
  }

  deletePrestation(form: UntypedFormGroup, arrayIndex: number, index: number) {
    const control = <UntypedFormArray>form.get('array')['controls'][arrayIndex].get('prestations');
    control.removeAt(index);
  }

  change(form: UntypedFormGroup, event: any, name: string) {
    let index = this.arrayIndex(form, name);
    if (event.target.checked) {
      this.addPrestation(form, index, event.target.value);
    } else {
      this.deletePrestation(form, index, this.prestationIndex(form, index, event.target.value))
    }
  }

  selectType(form: UntypedFormGroup, event: any) {
    if (event.target.checked) {
      this.prestationService.get_prestation_by_type(event.target.value).subscribe(prestation => {
        this.data[event.target.value] = prestation.map(items => {
          return items;
        })
      })
      this.addArray(form, null, event.target.value, null, null);
    }
    else {
      this.deleteArray(form, this.arrayIndex(form, event.target.value));
      this.data[event.target.value] = [];
    }
  }

  arrayIndex(form: UntypedFormGroup, name: string) {
    let array: Array<any> = form.value['array'];
    return array.findIndex(x => x.name == name);
  }

  prestationIndex(form: UntypedFormGroup, arrayIndex: number, name: string) {
    let array: Array<any> = form.value['array'][arrayIndex]['prestations'];
    return array.findIndex(x => x == name);
  }

  //horaire
  createDay(day: any, open: boolean, full_day: boolean, id: string) {
    return this.fb.group({
      day: day,
      open: open,
      full_day: full_day,
      id: id,
      horaires: new UntypedFormArray([])
    }
      , { validators: this.fullDateValidator('open', 'full_day', 'horaires') }
    )
  }

  createHoraires(heur_debut?: string, heur_fin?: string, ouverture?: string) {
    return this.fb.group({
      heur_debut: [heur_debut],
      heur_fin: [heur_fin],
      ouverture: ouverture
    }
      , { validators: this.timeValidator('heur_debut', 'heur_fin') }
    )
  }

  addHoraire(index: number, heur_debut?: string, heur_fin?: string, ouverture?: string) {
    const control = <UntypedFormArray>this.horaireForm.get('array')['controls'][index].get('horaires');
    control.push(this.createHoraires(heur_debut, heur_fin, ouverture));
  }

  getHoraires(form: any) {
    return form.controls.horaires.controls;
  }

  deleteSeletedHoraire(arrayIndex: number, index: number, horaire: any) {
    const control = <UntypedFormArray>this.horaireForm.get('array')['controls'][arrayIndex].get('horaires');
    control.removeAt(index);

  }

  deleteHoraires(arrayIndex: number) {
    const control = <UntypedFormArray>this.horaireForm.get('array')['controls'][arrayIndex].get('horaires');
    let length = control.value.length;
    for (let i = length - 1; i >= 0; i--) {
      control.removeAt(i);
    }
  }

  onFullDayChange(event: any, i: number) {
    if (event.target.checked) {
      this.deleteHoraires(i);
      return
    }
  }

  isChecked(i: number, form: UntypedFormGroup, control: string) {
    let state = form.value['array'][i][control];
    return state;
  }

  // social media
  createLinks(name: string, link: string) {
    return this.fb.group(
      {
        name: name,
        checked: true,
        link: [link, [Validators.required, this.formService.link_validator]],
        studio: null
      })
  }

  addLink(form: UntypedFormGroup, name: string, link: string) {
    const control = <UntypedFormArray>form.get('array');
    control.push(this.createLinks(name, link));
  }

  selectLink(form: UntypedFormGroup, event: any) {
    if (event.target.checked) {
      this.addLink(form, event.target.value, null);
    }
    else {
      this.deleteArray(form, this.arrayIndex(form, event.target.value));
    }
  }

  private timeValidator(beginHour: string, finalHour: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as UntypedFormGroup
      const valueOfBeginHour = formGroup.get(beginHour)?.value
      const valueOffinalHour = formGroup.get(finalHour)?.value

      let debut: any = moment(valueOfBeginHour, 'HH:mm'); //=> '2013-03-10T01:30:00-05:00'
      let fin: any = moment(valueOffinalHour, 'HH:mm'); //=> '2013-03-10T01:30:00-05:00'

      let difference = fin.diff(debut);

      if (difference > 0) {
        return null
      } else {
        return { valuesDoNotMatch: true }
      }
    }
  }

  private fullDateValidator(open: string, full_day: string, horaires: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as UntypedFormGroup
      const fullDayValue = formGroup.get(full_day)?.value
      const horaireValue = formGroup.get(horaires)?.value
      const openValue = formGroup.get(open)?.value

      if (!openValue || fullDayValue || horaireValue.length > 0) {
        return null
      } else {
        return { valuesDoNotMatch: true }
      }
    }
  }

  private checkTarif(tarif: string, tarif_promo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as UntypedFormGroup
      const tarif_promoValue = Number(formGroup.get(tarif_promo)?.value);
      const tarifValue = Number(formGroup.get(tarif)?.value);

      if ((tarifValue > tarif_promoValue) && tarif_promoValue >= 0) {
        return null
      } else if (tarif_promoValue < 0) {
        return { message: "- la valeur du tarif promo doit être supérieur ou égale à zéro" }
      } else {
        return { message: "- la valeur du tarif promo doit être inférieur que le tarif" }
      }
    }
  }

  deleteServerImage(index: number, image: any) {
    this.imagesToDelete.push(image.id)
    this.Form.value.medias.splice(index, 1);
  }

  back() {
    this.location.back();
  }

  loadUsers() {
    this.spinner.show();
    this.userService.get_alls("&type=gerant").subscribe(users => {
      this.users = users.map(items => {
        return { id: items.id, email: items.email }
      })
    }).add(() => {
    })
  }

  assigner() {
    this.spinner.show();
    this.userService.get_by_id(this.selectedUser.id).subscribe(user => {
      this.studioService.assignUser(this.studio.id, user).subscribe(res => {
        Swal.fire("Félicitation", "Studio assigné avec succès", "success");
        this.router.navigate(['/studios']);
      }).add(() => {
        this.spinner.hide();
      })
    })
  }



  enlever() {
    Swal.fire({
      title: "Étes-vous sûre de vouloir détacher cet utilisateur ?",
      cancelButtonText: 'Annuler',
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: `Détacher`,

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.spinner.show();
        if (this.studio.reservations == 0) {
          this.studioService.assignUser(this.studio.id, null).subscribe(res => {
            Swal.fire("Félicitation", "utilisateur détaché avec succès", "success");
            this.router.navigate(['/studios']);
          }).add(() => {
            this.spinner.hide();
          })
        } else {
          Swal.fire("Oupss", "impossible de détacher cet utilisateur,car il a des réservations dans ce studio", "info");
          this.spinner.hide();
        }
      }
    })

  }
  // 2021-06-17 16:00
  showDateHour(date: any) {
    return moment(date).utcOffset(0).format('DD-MM-YYYY HH:mm');
  }

  initDescription() {
    this.f.description.setValue(this.old_description);
  }

  generateDescriptionChatGpt() {
    let descriptionChatGPT = this.f.description.value;
    if (descriptionChatGPT.length > 0) {
      this.spinner.show();

      this.generate_chatgpt_description = true;
      this.studioService.generate_description_chatgpt(descriptionChatGPT).subscribe((res) => {
        if (typeof res === 'object') {
          if (res["error"].code == "context_length_exceeded") {
            Swal.fire("Attention", "Veuillez réduire le nombre de caractères dans votre message \n ( nombre maximum est 8000 )", "info");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Une érreur est survenue",
              footer: `<p class="alert alert-danger">${res["error"]?.message}</p>`
            });
          }
        } else {
          this.f.description.setValue(res);
        }

      }, (err) => {
        if (err.status === 200) {
          this.f.description.setValue(err.error.text);
        } else {
          Swal.fire("Erreur", "Une érreur est survenue", "error");
        }
      }).add(() => {
        this.generate_chatgpt_description = false;
        this.spinner.hide();
      });
    } else {
      Swal.fire("Attention", "veuillez saisir un text", "info");
    }
  }

  generate_avatar(avatar: string) {
    return (avatar.includes("res.cloudinary.com")) ? avatar : (this.base_api + avatar);
  }

  // dont't allow studio title to has symbols to prevent errors on webflow slug
  titleHasSymbol(title: string) {
    var format = /[^a-zA-Z0-9À-ÿ\s]/;
    return format.test(title);
  }
}
