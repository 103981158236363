import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  endPoint = "reservations";

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  create(data: any) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + this.endPoint, formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  update_reservation(id: string, data: any): Observable<any> {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + this.endPoint + "/" + id, formData,
      // etat,
      // is_deleted,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }
    );
  }


  create_payment_for_reservation(data: any) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + "payments", formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  get_by_id(id: string): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/" + id, { headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() } });
  }

  /*
  get_last(number: number) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.api + this.endPoint + "?_limit=" + number + "&_sort=createdAt:DESC", {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      }).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
    return promise
  }
  */

  get_last(number: number): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?_limit=" + number + "&_sort=createdAt:DESC&is_deleted=false", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }

  getQL(): Observable<any> {
    let query = '{"operationName":null,"variables":{},"query":"{reservations (sort: \\"createdAt:desc\\", limit: 200 ) { id start_date studio { name }  payment { type } user { email profile { phone full_name }}}}"}';
    return this.http.post(environment.api + "graphql", query, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  get(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?_limit=-1&_sort=createdAt:DESC&is_deleted=false", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  getByPaymentType(filters: string): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "?_limit=-1&_sort=createdAt:DESC" + filters, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }

  search(value: string): Observable<any> {
    let url = environment.api + this.endPoint + "?_where[_or][0][studio.name_contains]=" + value + "&_where[_or][1][user.email_contains]=" + value + "&_where[_or][2][user.profile.full_name_contains]=" + value + "";
    return this.http.get(url, { headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() } });
  }

  count(): Observable<any> {
    return this.http.get(environment.api + this.endPoint + "/count", {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    });
  }


}
