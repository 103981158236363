import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from '../../../../app/services/products/products.service';
import { StripeService } from '../../../../app/services/stripe/stripe.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-abonnement',
  templateUrl: './add-abonnement.component.html',
  styleUrls: ['./add-abonnement.component.css']
})
export class AddAbonnementComponent implements OnInit {
  submitted = false;
  Form: UntypedFormGroup;
  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private stripeService: StripeService,
    private productService: ProductsService,
  ) {
    this.Form = fb.group({
      name: ["", Validators.required],
      price: ["", Validators.required],
      description: ["", Validators.required],
      features: this.fb.array([this.createFeauture()])
    })
  }

  get f() {
    return this.Form.controls;
  }

  createFeauture() {
    return (this.fb.group({
      name: ["", Validators.required],
    }))
  }

  addFeature() {
    const control = <UntypedFormArray>this.Form.get('features');
    control.push(this.createFeauture());
  }
  
  deleteFeature(index:any){
    const control = <UntypedFormArray>this.Form.get('features');
    
    if(control.value.length>1){
      control.removeAt(index);
    }else{
      Swal.fire("Attention,","Il faut renseigner une caractéristique au moins !","info")
    }
  }

  getFeature(form) {
    return form.controls.features.controls;
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const values = this.Form.value;
    let features = [];
    for (let i = 0; i < values.features.length; i++) {
      features.push(values.features[i].name)
    }

    this.submitted = true;
    if (this.Form.controls.name.invalid || this.Form.controls.price.invalid || this.Form.controls.description.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier les champs vides', 'error');
      return
    }
    if (this.Form.controls.features.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier les champs vides des caractéristiques', 'error');
      return
    }
    this.spinner.show();
    this.stripeService.createProduct(values).then((stripeProduct:any) => {
      this.stripeService.createPrice(stripeProduct.id, values).then((stripePrice:any) => {
        this.productService.create(stripePrice.id, stripeProduct.id, values,features).subscribe(product => {
          Swal.fire('Félicitation', 'abonnement créé avec succès', 'success');
          this.router.navigate(["/abonnements"]);
        },err=>{
          Swal.fire('Erreur', 'Une érreur est survenue', 'error');
        })
      })
    }).finally(()=>{
      this.spinner.hide();
    })
  }
}
