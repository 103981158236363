import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HorairesService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create(form:any): Observable<any> {
    return this.http.post(environment.api + 'horaires', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(),"content-type":"application/json" }
    });
  }

  delete(form:any): Observable<any> {
    return this.http.post(environment.api + 'horaires/delete', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(),"content-type":"application/json" }
    });
  }
}
