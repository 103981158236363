export class Studio {
    id: string;
    name: string;
    adresse: string;
    city: string;
    country: string;
    lat: string;
    lng: string;
    postal_code: string;
    route: string;
    phone: string;
    email: string;
    siren: string;
    description: string;
    ical: string;
    web_site: string;
    isSponsored: boolean;
    is_draft: boolean;
    commission = 0;
    minhours_day = 0;
    minhours_semiday = 0;
    has_smok: boolean;
    has_internet: boolean;
    has_cabin: boolean;
    has_rest_room: boolean;
    has_kitchen: boolean;
    is_enregistrement: boolean;
    is_mix: boolean;
    is_mastering: boolean;
    is_beatmaking: boolean;
    has_bathroom: boolean;

    constructor(
        data: Partial<Studio>
    ) {
        Object.assign(this, data);
    }
}
